sbmn-mta-main {
  min-height: 400px;
  display: block;
}

.mtamain {
  max-width: 950px;
  margin: 10px auto 0;
  padding-bottom: 50px;
  overflow: hidden;
  @include mq('tablet', 'max') {
    padding: 20px 0 40px;
    width: 100%;
    margin-bottom: 0;
  }

  &__logo {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto 20px;
    position: relative;
    max-width: 600px;
    cursor: pointer;

    &:hover {
      img {
        opacity: 0.2;
      }

      figcaption {
        opacity: 1;
        color: $Bprimary-light;
      }
    }

    figcaption {
      font-size: 2rem;
      flex-direction: column;
      right: 0;
      top: 0;
    }

    &__figcaption,
    &__nologo {
      opacity: 0;
      transition: opacity 0.7s linear;
      width: 100%;
      height: 100%;
      display: flex;
      text-align: center;
      justify-content: center;
      right: 0;

      &:before {
        color: $Bthird;
        font-size: 2.5rem;
      }
    }

    &__nologo {
      opacity: 1;
      min-height: 100px;
      color: $Bgrey6;
      flex-direction: column;
      &:before {
        transition: color 0.2s linear;
        font-size: 6rem;
      }
      &:hover {
        &:before {
          color: $Bsecondary;
        }
      }
    }

    img {
      max-width: 100%;
      max-height: 125px;
    }

    &__uploader {
      width: 90%;
      margin: 5px auto;
      color: $Bwhite;
      top: 0;
      left: 0;
      z-index: z(level6);
    }
  }

  &__container {
    display: flex;
    transition: left 0.2s linear;
    position: relative;
    align-items: flex-start;

    &.main {
      left: 0;

      fieldset {
        padding: 5px 0;
      }
    }

    &.fee {
      left: -100%;
    }

    &.more {
      left: -200%;
    }

    &__section {
      @extend .passengers__section;
      min-width: 100%;
      padding: 0 10px;

      &.cash {
        @include mq('tablet', 'max') {
          h2 {
            padding-left: 25px;
          }
          tr {
            padding: 5px 10px;
            border-bottom: 1px solid $Bgrey3;

            &:nth-child(odd) {
              background: $Bwhite;
            }
          }
          td,
          td:first-child,
          td:last-child {
            padding-left: 0;
            padding-right: 0;
          }
          mat-form-field {
            max-height: 21px;
            margin-right: 0;

            input {
              text-align: right;
            }
          }
          .mat-form-field-infix {
            border-top: 0;
          }
        }
      }

      &:before {
        color: $Bsecondary;
        width: 40px;
      }

      mat-form-field,
      mat-checkbox {
        width: 30%;
        margin: 0 3% 0 0;

        &.large {
          width: 90%;
        }

        @include mq('tablet', 'max') {
          width: 95%;
          display: block;
          margin-bottom: 10px;
        }
      }

      &__title {
        @extend .passengers__section__title;
        margin: 0 0 30px 40px;

        &__saved {
          font-size: 0.8em;
          opacity: 0;
          margin-left: 20px;
          color: $Bprimary-lighter;
          line-height: 1em;
          transition: opacity 0.7s linear;

          &.in {
            opacity: 1;
          }
        }
      }

      &__table {
        width: 100%;
        margin: auto;
        text-align: center;
        border-collapse: separate;
        border: 0;

        &__agency-fee {
          @include mq('tablet', 'max') {
            padding-top: 16px;
            padding-bottom: 0;
            mat-form-field {
              min-width: 80px;
              width: 100px;
            }
          }
        }

        td {
          position: relative;
          border-bottom: 1px solid $Bgrey5;

          &:last-child {
            font-family: basicbold, Arial, sans-serif;
          }

          .Berror {
            bottom: 5px;
            display: inline-block;
            width: 100%;
            text-align: center;
          }
        }

        input {
          text-align: center;
        }
      }

      &__container {
        width: 100%;
        margin: 0 auto 20px;
        display: flex;
        flex-wrap: wrap;

        &__title {
          margin: 5px auto 10px;
          width: 100%;
        }

        &__fieldlarge {
          width: 100%;
        }

        &__field {
          padding-right: 3%;
          &__half {
            flex-basis: 50%;
          }
          &:nth-of-type(1) {
            width: 60%;
            @include mq('tablet', 'max') {
              width: 97%;
            }
          }

          &:nth-of-type(2) {
            width: 39%;
            @include mq('tablet', 'max') {
              width: 97%;
            }
          }

          &.valigned {
            display: flex;
            justify-content: space-between;
            flex-direction: column;
          }

          &__freetext {
            width: 100%;
            height: 100px;
            margin: 10px auto;
          }

          &__note {
            margin-bottom: 35px;
          }

          mat-form-field {
            width: 100%;
          }

          mat-checkbox {
            width: 100%;
            margin-top: 20px;
          }

          mat-radio-group {
            margin: 10px;
            display: flex;
            flex-direction: column;
          }

          mat-radio-button {
            margin: 5px 0;
          }

          mat-slide-toggle.mat-mdc-slide-toggle {
            & .mdc-switch.mdc-switch--selected:enabled .mdc-switch__handle::after,
            &
              .mdc-switch.mdc-switch--selected:enabled:hover:not(:focus):not(:active)
              .mdc-switch__handle::after,
            &
              .mdc-switch.mdc-switch--selected:enabled:focus:not(:active)
              .mdc-switch__handle::after {
              background: var(--ct-color_aux);
            }
            & .mdc-switch:enabled .mdc-switch__track::after,
            & .mdc-switch:enabled:hover:not(:focus):not(:active) .mdc-switch__track::after,
            & .mdc-switch:enabled:focus:not(:active) .mdc-switch__track::after {
              background: $Bgrey3;
            }
          }
        }
      }
    }
  }
}
