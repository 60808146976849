sbmn-header {
  display: block;
  z-index: z(level3);
  position: relative;
}

#main_header {
  background: $Bprimary-dark;
  padding: 0 10px;
  height: 80px;
  max-width: unset;
  font-weight: 500;
  &,
  div,
  nav,
  a {
    display: flex;
    align-items: center;
  }

  @include mq('tablet', 'max') {
    position: fixed;
    z-index: z(level6);
    top: 0;
    height: 60px;
  }

  .main_header__logo {
    &:before {
      margin: 0 -5px;
      left: 0;
      bottom: 18px;
      transform: rotate(-270deg);
    }

    @include mq('tablet', 'max') {
      min-width: calc(100% - 100px);
      margin-right: auto;
      margin-left: auto;
      &:before {
        top: -5px;
        left: 50%;
        margin-left: -5px;
      }
    }
  }

  figure {
    @extend %Bstfigure;
    max-width: 300px;
    display: flex;
    align-items: center;
    margin: 0;
    padding-right: 30px;
    @include mq('tablet', 'max') {
      max-width: 60%;
      padding: 0;
      margin: 0 auto;
      line-height: 65px;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        align-self: center;
        max-width: 220px;
      }
    }
  }

  figcaption {
    color: $Bsecondary;
    font-size: 2rem;
    font-family: basicbold, Arial, sans-serif;
    margin-left: 10px;
    position: absolute;
    top: 0;
    right: 0;
    @include mq('tablet', 'max') {
      font-size: 1.5rem;
      top: -25px;
    }
    @include mq('phablet', 'max') {
      font-size: 1rem;
    }
  }

  a {
    position: relative;
    align-items: center;
    color: $Bgrey3;
    text-transform: uppercase;
    padding: 0 10px;
    font-size: 1.5rem;
    text-decoration: none;

    &:before {
      content: '';
      @include triangle($Bfourth, 5px, 7px);
      position: absolute;
      opacity: 0;
      transition: opacity 0.2s linear;
      bottom: 25px;
      margin: -5px;
      left: 50%;
    }

    &:hover,
    &.active {
      color: $Bsecondary;
      &:before {
        opacity: 1;
      }
    }
  }

  .msgbullet {
    position: absolute;
    top: 17px;
    right: calc(50% - 9px);
    padding-top: 2px;
    @include mq('tablet', 'max') {
      position: relative;
      right: unset;
      top: 0;
      margin-right: 5px;
    }
  }

  .poweroff {
    display: flex;
    align-items: center;
    font-size: 0;
    color: transparent;
    cursor: pointer;
    padding: 10px 20px;
    position: absolute;
    right: 2px;

    &:before {
      font-size: 3rem;
      color: $Bgrey4;
      transition: color 0.2s linear;
    }

    &:hover:before {
      color: $Bsecondary;
    }

    @include mq('tablet', 'max') {
      position: absolute;
      right: 0;
      transform: scale(0.9);
    }
  }

  a.header__balance {
    font-size: 2rem;
    font-family: basicbold, Arial, sans-serif;
    font-weight: lighter;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 20px;
    border: solid $Bprimary-lighter;
    border-width: 0 2px 0 0;
    margin: 0 10px;
    color: $Bsecondary;
    transition: color 0.2s linear;
    position: absolute;
    right: 65px;

    &:before {
      display: none;
    }
    span {
      color: $Bwhite;
      font-size: 0.5em;
    }

    &:hover {
      border-top-color: transparent;
      span {
        color: $Bsecondary;
      }
    }

    @include mq('tablet', 'max') {
      align-items: flex-start;
      border-right: 0;
      border-top: 2px solid $Bprimary-lighter;
      padding: 20px 0 0 20px;
      margin: 20px 10px;
      font-size: 3rem;
      width: 93%;
      right: 0;
      position: relative;
      &:hover {
        border-top-color: $Bprimary-lighter;
      }
    }
  }

  .desktop_menu_container {
    @include mq('tablet', 'max') {
      display: none;
    }
  }

  .mobile_menu_container {
    @include mq('tablet', 'min') {
      display: none;
    }

    &__burger {
      cursor: pointer;
      z-index: 10;
      position: fixed;
      left: 20px;
      display: flex;
      flex-direction: column;
      top: 15px;

      &__bar1,
      &__bar2,
      &__bar3 {
        width: 35px;
        height: 3px;
        background-color: $Bwhite;
        margin: 4px 0;
        transition: 0.4s;
      }
    }

    &__sidenav {
      display: flex;
      flex-direction: column;
      color: white;
      background-color: black;
      height: 100vh;
      position: absolute;
      left: -320px;
      transition: 0.3s;
      width: 300px;
      opacity: 0;
      top: -15px;

      &__title {
        display: block;
        margin: 24px auto;
        font-size: 2rem;
      }

      &__options {
        display: flex;
        flex-direction: column;
        margin-top: 25px;
        width: 100%;
        align-items: flex-start;

        &__option {
          font-size: 2rem;
          margin: 20px;
          border: none;
          color: $Bwhite;
          &:before {
            bottom: 11px;
            left: -5px;
          }
        }
      }
    }

    .-change {
      .mobile_menu_container {
        &__sidenav {
          left: -20px;
          opacity: 1;
        }

        &__burger {
          &__bar1 {
            transform: rotate(-45deg) translate(-15px, 10px);
          }

          &__bar2 {
            opacity: 0;
          }

          &__bar3 {
            transform: rotate(45deg) translate(-6px, -1px);
          }
        }
      }
    }
  }

  &.sticky {
    @include mq('tablet', 'min') {
      box-shadow: 0 8px 12px -6px rgba($Bblack, 0.5);
      position: fixed;
      z-index: z(level2);
      height: 45px;
      animation: headerIn 1s forwards;
      .main_header__logo {
        border-width: 2px 0 0 0;
      }
      figcaption {
        left: 209px;
        font-size: 1.5rem;
        top: 5px;
      }
      img {
        width: 75%;
      }
      nav a {
        border-width: 2px 0 0 0;
      }
      .header__balance {
        right: 40px;

        span {
          display: none;
        }
      }
      .msgbullet {
        color: $Btransparent;
        @include circle(5px);
        right: calc(50% - 2.5px);
        top: 18px;
      }
      .poweroff {
        padding: 10px;
        &:before {
          font-size: 2rem;
        }
      }
    }
  }
}

#fixstickyheader {
  height: 80px;
  @include mq('tablet', 'max') {
    display: none;
  }
}
