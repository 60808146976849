sbmn-flights-voucher {
  display: block;
  padding: 0 0 40px 0;

  strong {
    font-family: basicbold, Arial, sans-serif;
    font-weight: lighter;
  }

  .brand_header .h1withbold {
    color: $Bgrey6;
  }

  @include mq('tablet', 'max') {
    padding: 0;
  }
}

.voucher {
  margin: 10px auto 40px auto;
  max-width: map_get($breakpoints, 'tablet');
  min-width: map_get($breakpoints, 'tablet');
  border: 1px solid $Bgrey5;
  background: $Bwhite;
  border-radius: 0 20px 0;
  font-family: special, Arial, sans-serif;

  &__header {
    padding: 0;
    border-bottom: 1px solid $Bprimary-dark;

    &__container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0;

      &__contimg {
        display: flex;
        align-items: center;
        width: 100%;
        max-width: 600px;

        img {
          max-width: 550px;
          max-height: 100px;
          margin: 10px 25px;
        }

        .h1withbold {
          line-height: 1;
          padding: 20px;
          text-align: center;
          font-size: 3rem;
          background: $Bgrey3;
          width: 100%;
          min-height: 120px;
          margin: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          color: $Bwhite;
        }
      }

      &__state-loc {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        padding: 17px 20px;

        &__state {
          background-color: $Bprimary-light;
          font-size: 1.5rem;
          padding: 5px 15px;
          border-radius: 6px;
          font-family: basicbold, Arial, sans-serif;
          font-weight: lighter;
          line-height: 20px;
          text-align: center;
          width: auto;
          color: $Bwhite;

          .INI & {
            background-color: $Borange-red;
          }

          &__text {
            text-transform: uppercase;
            display: block;
          }
        }

        &__loc {
          color: $Bprimary-light;
          margin-bottom: 0;
          margin-top: 20px;
          text-align: right;
          font-size: 1.5em;
          font-family: basicbold, Arial, sans-serif;
          font-weight: lighter;
        }

        &__subloc {
          color: $Bprimary-light;
          text-align: right;
          margin-top: 2px;
          font-size: 1.3em;
          font-family: basicbold, Arial, sans-serif;
          font-weight: lighter;
        }

        .localizador:before {
          margin-right: 5px;
          font-size: 12px;
        }
      }

      &__warning {
        margin: 20px 0 0;
        font-size: 1.5rem;
        line-height: 1.2;
        color: orange;
        padding-right: 0;
      }
    }
  }

  &__container {
    padding: 0 40px 50px;

    &__limit {
      color: $Berror;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      font-size: 1.4rem;
      font-family: basicbold, Arial, sans-serif;
      font-weight: lighter;
      margin-top: 15px;
    }

    &__passenger {
      margin: 20px 0;
      display: flex;
      position: relative;

      &__container {
        display: flex;
        flex-direction: column;
        width: 100%;

        &__title {
          font-size: 2.1em;
          color: $Bprimary-dark;
          margin-top: 0;
        }

        &__group {
          display: flex;
          flex-wrap: wrap;

          &__title {
            font-size: 1.6rem;
            margin-top: 0;
            margin-bottom: 20px;
          }

          &__info {
            border-bottom: 1px solid $Bgrey1;
            padding: 10px 20px 10px 0;
            align-items: center;
            min-width: 30%;
            margin: 0 1.5%;
            display: flex;

            &:before {
              color: $Bgrey5;
              font-size: 40px;
              padding-top: 5px;
            }

            &__container {
              line-height: 1.5;
              margin-left: 11px;
              display: flex;
              flex-direction: column;
              font-size: 1.5em;

              &__name {
                font-family: basicbold, Arial, sans-serif;
                font-weight: lighter;
                line-height: 1;
              }

              &__year,
              &__state {
                color: $Bgrey5;
              }
            }
          }
        }
      }
    }

    &__flights {
      padding-bottom: 10px;
      position: relative;

      &__container {
        &__info {
          display: flex;
          justify-content: space-between;
          align-items: center;

          &__title {
            font-size: 2.1em;
            color: $Bprimary-dark;
            margin: 0;
          }

          &__company {
            margin: 0;
            color: $Bgrey5;
            display: flex;
            align-items: center;
            font-size: 1.5em;

            &__name {
              margin-right: 15px;
            }

            &__logo {
            }
          }
        }

        &__content {
          &:nth-child(odd) .avion:before {
            transform: rotate(225deg);
          }

          &__info {
            justify-content: space-between;
            background-color: $Bprimary-lighter;
            padding: 10px 15px;
            display: flex;
            align-items: center;

            &__left {
              line-height: 20px;
              text-transform: uppercase;
              flex-direction: column;
              display: flex;
              justify-content: space-between;
              color: $Bwhite;

              &__title {
                margin: 0;
              }

              &__date {
                font-size: 1.6rem;
              }
            }

            &__right {
              &__duration {
                font-size: 1.5em;
                background-color: $Bwhite;
                padding: 5px 10px;
                border-radius: 100px;
                color: $Bgrey7;
                display: flex;
                align-items: center;

                &:before {
                  font-size: 20px;
                  margin-right: 10px;
                }
              }
            }
          }

          &__flight {
            &__info {
              width: 95%;
              margin: 10px;
              display: flex;
              font-size: 1.6rem;

              &__left {
                width: 70%;
                display: flex;

                &:before {
                  @include circle(50px);
                  color: $Bgrey3;
                  font-size: 30px;
                  border: 1px solid $Bgrey3;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  margin-top: 10px;
                  position: absolute;
                  transform: rotate(45deg);
                }

                &__text {
                  width: 100%;
                  padding-left: 70px;
                  padding-right: 20px;

                  &__title {
                    margin: 10px 0 3px;
                    font-size: 2rem;
                    color: $Bgrey3;
                  }

                  &__dates {
                    color: $Bgrey8;
                    margin-bottom: 5px;
                  }

                  &__from-to {
                    margin: 10px 0;
                    line-height: 21px;
                  }
                }
              }

              &__right {
                width: 30%;
                margin-top: 10px;
                line-height: 1.3;
                font-size: 1.5rem;
                padding-left: 3%;
              }
            }

            &__stops {
              position: relative;
              font-size: 1.6rem;
              text-align: center;
              display: flex;
              justify-content: center;
              align-items: center;
              margin: 0 20px 20px;

              &:before,
              &:after {
                content: '';
                display: inline-block;
                width: 25%;
                height: 1px;
                border-top: 2px dotted $Bgrey3;
                top: 7px;
                left: 0;
              }

              &__container {
                display: flex;
                background-color: white;
                z-index: z(level2);
                padding: 0 30px;

                &__logo {
                  &:before {
                    border: 1px solid black;
                    border-radius: 100%;
                    padding: 4px;
                    background-color: $Bsecondary;
                    margin-right: 10px;
                  }
                }

                &__hours {
                  margin-left: 5px;
                  min-width: 70px;
                }
              }
            }
          }
        }
      }
    }

    &__fares {
      margin: 40px 0 50px;
      height: auto;
      display: flex;
      position: relative;

      &__container {
        width: 100%;

        &__title {
          font-size: 2.1em;
          color: $Bprimary-dark;
          margin-top: 0;
        }

        &__table {
          font-size: 1.5em;
          width: 100%;
          text-align: center;
          line-height: 25px;
          table-layout: fixed;

          &__head {
            text-transform: uppercase;
            color: $Bgrey5;

            th {
              font-weight: normal;
            }
          }

          &__body {
            line-height: 30px;

            td {
              border-top: 1px solid $Bgrey2;
            }

            &__passenger {
              text-align: left;
              line-height: 1.1;
            }

            &__tot-cell {
              font-family: basicbold, Arial, sans-serif;
              font-weight: lighter;
            }

            &__total {
              td {
                text-align: right !important;
              }

              &__text {
                padding-right: 11px;
                padding-top: 11px;

                &__price {
                  color: $Bprimary;
                  padding: 5px 15px;
                  font-family: basicbold, Arial, sans-serif;
                  font-weight: lighter;
                  margin-left: 10px;
                  font-size: 3rem;
                }
              }
            }

            &__extra {
              td.extra {
                text-align: right;
                font-size: 1.3rem;
                font-weight: normal;
                color: $Bgrey8;
                padding-right: 5%;

                strong {
                  color: $Bblack;
                  font-size: 1.5rem;
                }
              }
            }
          }
        }
      }
    }

    &__reco,
    &__important,
    &__info {
      margin: 30px 0;

      &__title {
        border-bottom: 1px dashed $Bgrey4;
        padding-bottom: 2px;
      }

      &__text {
        font-size: 1.5em;
        color: $Bgrey5;
        line-height: 22px;

        ul {
          padding-left: 15px;
        }
      }
    }
  }
}

.voucher__nav {
  margin: 10px auto 0;
  max-width: map_get($breakpoints, 'tablet');
  display: flex;
  justify-content: flex-end;

  .pdf {
    height: 60px;
  }
  .insurance_icon {
    position: relative;
    top: 5px;
    justify-content: center;
    @include mq('tablet', 'max') {
      top: 0;
    }
  }
}

.voucher__sendBondForm {
  display: flex;
  max-width: map_get($breakpoints, 'phablet');

  mat-form-field {
    margin-right: 1rem;

    mat-hint {
      color: var(--ct-color_aux) !important;
      font-size: large !important;
    }
  }

  button {
    padding: 0px 10px;
    font-size: xx-large;
    cursor: pointer;
    text-decoration: none;
    line-height: 1;
    color: var(--ct-color_aux);
    transition: none !important;
    border-width: 0px !important;
    background-color: transparent;
  
    &[disabled] {
      background-color: transparent;
      color: $Bgrey5;
      cursor: not-allowed;
      border: 1px solid $Btransparent;
  
      &:focus,
      &:active,
      &:hover {
        border: 1px solid $Btransparent;
        box-shadow: none;
      }
    }
  
    &:focus,
    &:active,
    &:hover {
      text-decoration: none;
    }
  }
}

.responsive .voucher {
  min-width: 0;
  @include mq('tablet', 'max') {
    margin-top: 0;
    margin-bottom: 0;
    border: none;
    &__header {
      &__container {
        margin: 0;
        flex-direction: column;
        align-items: center;

        &__contimg {
          justify-content: center;
        }

        &__image {
          max-width: 90%;
        }

        &__state-loc {
          align-items: center;

          &__state {
            font-size: 1.3rem;
            max-width: 95%;
          }
        }
      }
    }
    &__container {
      padding: 10px 10px 10px 0;

      &__limit {
        &__text {
          line-height: 1;
          @include mq('tablet', 'max') {
            padding: 0 20px;
          }
        }
      }

      &__passenger {
        margin: 20px 0 35px;

        &:before {
          width: 10px;
        }

        &__container {
          margin-left: 20px;
          padding: 10px 0px;
          min-width: calc(100% - 20px);

          &__title {
            font-size: 1.8em;
          }

          &__group {
            &__info {
              min-width: 100%;
              margin-bottom: 15px;

              &:last-child {
                margin-bottom: 0;
              }
            }
          }
        }
      }

      &__flights {
        &:before {
          width: 10px;
        }

        &__container {
          padding: 10px 0px;
          margin-left: 20px;
          margin-bottom: -30px;

          &__info {
            flex-direction: column;
            align-items: flex-start;
            margin-bottom: 10px;

            &__title {
              font-size: 1.8em;
            }

            &__company {
              width: 100%;
              justify-content: space-between;

              &__logo {
                width: 38px;
              }
            }
          }

          &__content {
            &__info {
              flex-direction: column;
              align-items: flex-start;
              padding-bottom: 20px;

              &__left {
                margin-bottom: 15px;
                font-size: 0.9em;

                &__title {
                  margin-bottom: 5px;
                }
              }
            }

            &__flight {
              &__info {
                width: 100%;
                flex-direction: column;
                margin-left: 0;
                margin-bottom: 0;

                &__left,
                &__right {
                  width: 100%;

                  &__text {
                    padding-left: 55px;
                  }
                }

                &__right {
                  padding-left: 55px;
                  margin-top: -5px;

                  div {
                    margin-bottom: 5px;
                  }
                }

                &__left {
                  &:before {
                    width: 40px;
                    height: 40px;
                    min-width: 40px;
                    min-height: 40px;
                    font-size: 20px;
                  }

                  &__text {
                    &__title {
                      margin: 10px 0 10px;
                    }

                    &__from-to {
                      div:first-child {
                        margin-bottom: 5px;
                      }
                    }
                  }
                }
              }

              &__stops {
                margin: 0;

                &:before,
                &:after {
                  display: none;
                }

                &__container {
                  align-items: center;
                  justify-content: center;
                  border-top: 1px dotted $Bgrey3;
                  border-bottom: 1px dotted $Bgrey3;
                  padding: 20px 0;
                  margin: 0 25px 20px;
                  width: 90%;

                  &__logo {
                    flex-basis: 4%;
                    height: 17px;
                  }

                  span {
                    flex-basis: 60%;
                  }
                }
              }
            }
          }
        }
      }

      &__fares {
        &:before {
          width: 10px;
        }

        &__container {
          margin-left: 20px;
          padding: 10px 0;

          &__title {
            font-size: 1.8em;
          }

          &__table {
            &__body {
              tr {
                &:nth-of-type(even) {
                  background: $Bgrey05;
                }

                &:last-child {
                  background: $Btransparent;
                }

                td:last-child {
                  border: 0;
                }
              }

              td {
                border-top: 0;
              }
            }
          }
        }
      }

      &__reco,
      &__important,
      &__info {
        padding-left: 20px;

        &__title {
          font-size: 1.8rem;
        }

        &__text {
          font-size: 1.6rem;
        }
      }
    }
  }
}
