sbmn-my-bookings-manager {
  + #mybookings {
    max-height: calc(100vh - 315px);
    overflow: hidden;
    min-height: 0;
  }

  .stopper-modal__scroll__container {
    text-align: left;
    color: $Bblack;
    top: 0;
  }

  .stopper-modal__scroll__container__title {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 70px;
    flex-wrap: wrap;
    font-size: 3rem;
    span {
      width: 100%;
      text-align: center;
    }

    em {
      color: var(--ct-color_aux);
      font-size: 2rem;
      display: block;

      &.cancelled {
        color: $Borange-red;
      }

      &.booked {
        color: $Bgreen;
      }

      &.book-error {
        color: $Berror-light;
        font-weight: bold;
        font-size: 1.5rem;
      }
    }

    .split {
      width: 40px;
      height: 40px;
      min-width: 40px;
      min-height: 40px;

      &:before {
        font-size: 3rem;
      }
    }
  }

  .stopper-modal__scroll__container__content {
    margin-top: 20px;
    display: flex;

    &.white_modal {
      padding-top: 0;
      padding-bottom: 0;
      min-height: 300px;
      background: $Bgrey05;
    }

    > div {
      position: relative;
      width: 100%;
      padding: 5% 0;
      justify-content: center;
      display: flex;
    }
  }

  .loader_abs {
    background: rgba($Bgrey05, 0.7);
  }

  .cssload-loader__container {
    position: relative;
    background: transparent;
  }

  sbmn-new-search {
    margin: 10px 10px 0 0;
  }

  sbmn-flights-voucher {
    @include mq('tablet', 'max') {
      max-width: 100%;
    }

    .brand_header {
      background: $Btransparent;
      border-top: 1px solid $Bprimary-lighter;
      position: absolute;
      width: 100%;
      text-align: left;
    }

    &:after {
      display: none;
    }
  }

  .bmanager {
    &__nav {
      margin: 20px auto 0 auto;
      max-width: map_get($breakpoints, 'tablet');
      display: flex;
      justify-content: space-between;
      min-height: 80px;
      @include mq('tablet', 'max') {
        flex-direction: column;
        min-height: 0;
        max-width: 100%;
      }

      &__section {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-grow: 2;
        &:last-child {
          justify-content: flex-end;
        }
        @include mq('tablet', 'max') {
          justify-content: center;
          flex-wrap: wrap;
        }

        &__item {
          position: relative;
          cursor: pointer;

          &.warning:before {
            position: absolute;
            top: 2px;
            right: 2px;
            color: $Borange-red;
          }

          .refund:before {
            color: lighten($Bgreen, 15%);
          }

          .info3:before,
          .agency_data:before,
          .msg-flights:before,
          .billete:before,
          .agency_data:before,
          .refund:before {
            font-size: 3rem;
          }
        }
      }
    }

    &__cancel {
      text-align: center;

      &__title {
        color: $Bprimary-light;
      }

      &__nobutton {
        margin: 5px 10px 20px auto;
      }

      &__button {
        margin: 5px auto 20px 10px;
      }
    }
  }
}
