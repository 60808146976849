sbmn-flights-search-engine {
  position: relative;
  width: 100%;
  display: block;

  .hhide & {
    background: $Btransparent;
  }
}

#gradient_search-engine {
  position: absolute;
  display: block;
  width: 100%;
  top: 0;
  height: 100%;
  left: 0;

  &.Simpleborder,
  &.Calendarioborder,
  &.Multidestinoborder {
    span {
      font-family: basiclight, Arial, sans-serif;
      color: $Bwhite;
      font-size: 3em;
      top: 10px;
      right: 5px;
      display: block;
      position: absolute;
      text-align: right;
      animation: searchtype 0.2s linear forwards;
      @include mq('tablet', 'max') {
        width: 100%;
        display: block;
        margin: 90px auto 0 -20px;
        max-width: unset;
        text-align: center;
      }
    }

    strong {
      font-family: basicbold, Arial, sans-serif;
    }
  }
}

#main_search-engine {
  position: relative;
  width: 100%;
  margin: auto;
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  @include mq('tablet', 'max') {
    flex-direction: column;
  }
}
sbmn-flights-search-engine.hide #main_search-engine .simpledates_dateview::before,
sbmn-flights-dashboard #main_search-engine .simpledates_dateview::before {
  content: 'calendar_month';
  font-family: 'Material Icons';
  /*-webkit-font-feature-settings: 'liga';*/
  font-size: 3rem;
  color: #96b8d3;
}

#nav_search-engine {
  display: flex;
  justify-content: stretch;
  background: $Btransparent;
  min-height: 50px;
  z-index: z(level1);
  @include mq('tablet', 'max') {
    flex-direction: row;
    position: absolute;
    top: -25px;
    width: 100%;
  }
  span {
    color: $Btransparent;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: content-box;
    font-size: 0;
    min-width: 130px;
    cursor: pointer;
    transition: background 0.1s linear;
    box-shadow: inset 0px -9px 14px -14px $Bprimary-dark-gradient3;
    background: $Bgrey1;
    &:hover,
    &.selected {
      background: $Bwhite;
    }
    @include mq('tablet', 'max') {
      border-width: 0 2px 2px 2px;
      height: 50px;
      margin-top: 5px;
      width: 100%;
    }
    &:before {
      font-size: 2.5rem;
      color: $Bprimary;
      opacity: 0.7;
      position: absolute;
      transition: transform 0.2s linear;
    }

    &.selected {
      overflow: hidden;
      margin-right: -2px;
      box-shadow: none;
      opacity: 1;
      background: $Bwhite;
      &:before {
        transform: scale(1.2);
        color: $Bsecondary;
      }

      @include mq('tablet', 'max') {
        border-width: 2px 2px 0 2px;
        margin-right: 0;
        margin-top: 0;
        height: 55px;
      }
    }

    &.multitrayecto {
      border-color: $Bgrey2;
      border-width: 0 1px;
      border-style: solid;
    }
  }
}

#searchEngineForm {
  display: flex;
  flex-wrap: wrap;
  font-size: 1.3rem;
  overflow: hidden;
  min-height: 300px;
  box-shadow: 0px 0px 18px -3px $Bprimary-dark-gradient3;
  border-radius: 0 20px;
  &:before {
    border-radius: 0 20px;
    @extend .Bborder-b;
  }

  .Simple_search & {
    background: $Bwhite;
  }
  .Multidestino_search & {
    background: $Bwhite;
  }
  .Calendario_search & {
    background: $Bwhite;
  }

  @include mq('tablet', 'max') {
    flex-direction: column;
    margin-top: 30px;
    border-radius: 0;
  }

  .mat-form-field-appearance-legacy .mat-form-field-underline {
    background: rgba($Bgrey4, 0.5);
  }

  .mat-select-arrow {
    border-top-color: rgba($Bgrey4, 0.5);
  }
}

#destdates_search-engine {
  @include w(75%);
  display: flex;
  @include mq('tablet', 'max') {
    width: 100%;
    margin: 0;
    max-width: unset;
    flex-direction: column;
    order: 0;
  }

  .destdate_container {
    flex-direction: column;
    padding: 5% 0 5% 7%;
    @include mq('tablet', 'max') {
      padding: 2%;
    }
    .Multidestino_search & {
      padding: 1% 0;
      flex-direction: row;
      &:first-child {
        padding-top: 2%;
      }
      @include mq('tablet', 'max') {
        flex-direction: column;
      }
    }
  }

  > section,
  .destdate_container {
    position: relative;
    display: flex;
    flex-grow: 2;
    justify-content: space-around;
    @include mq('tablet', 'max') {
      flex-direction: column;
    }
  }

  .idavuelta {
    @include circle(23px);
    display: flex;
    cursor: pointer;
    position: absolute;
    left: 15px;
    transform: rotate(90deg);
    margin: 0 auto;
    border: 1px solid $Bprimary-lighter;
    transition:
      color 0.2s linear,
      border 0.2s linear;

    &:hover {
      border-color: $Bsecondary;

      &:before {
        color: $Bsecondary;
      }
    }

    &:before {
      font-size: 1.5rem;
      color: $Bprimary-lighter;
    }

    .Multidestino_search & {
      @include circle(20px);
      display: flex;
      position: relative;
      transform: rotate(0);
      margin-top: 17px;
      @include mq('tablet', 'max') {
        transform: rotate(90deg);
        margin: 5px 0 0 -10px;
        left: 50%;
      }
      &:before {
        font-size: 1.2rem;
      }
    }

    @include mq('tablet', 'max') {
      margin: 0 auto;
      left: unset;
      right: 2px;
    }
  }

  mat-form-field {
    overflow: hidden;
    padding: 0 20px;
    max-height: 50px;
    font-size: 1.4rem;
    .Multidestino_search & {
      padding: 0;
      margin-left: 30px;
      @include mq('tablet', 'max') {
        margin: 0 5%;
        width: 90%;
        max-height: 50px;
      }
    }
    @include mq('tablet', 'max') {
      width: 100%;
      max-width: unset;
      max-height: 70px;
      font-size: 1.7rem;
    }

    &:before {
      content: unset;
      display: none;
    }

    &.calendar {
      &:before {
        left: 0;
        font-size: 1.5rem;
      }
    }
  }

  .mat-form-field-empty {
    //color: $Bwhite;
  }

  .Multidestino_search & {
    > section {
      flex-wrap: wrap;
    }

    mat-form-field.calendar {
      max-width: 25%;
      min-width: 25%;
      @include mq('tablet', 'max') {
        max-width: 100%;
      }
    }

    .addRoute {
      display: flex;
      align-items: center;
      cursor: pointer;
      opacity: 0.5;
      margin: 10px 0;
      width: 100%;
      justify-content: center;
      @include mq('tablet', 'max') {
        justify-content: center;
        margin: 20px;
        opacity: 0.8;
      }

      &:hover {
        opacity: 1;

        .Bplus {
          //color: $Bwhite;
          background: $Bprimary;
        }
      }
    }

    .Bless {
      position: absolute;
      right: 0;
      top: 15px;
    }

    .destdate_container {
      @include mq('tablet', 'max') {
        margin-bottom: 0;
        padding-bottom: 20px;
        border-bottom: 1px solid $Bgrey5;
        margin-top: 15px;
      }
    }
  }
}

#desdate_container_multiinfo {
  display: none;
}

#simpledates_search-engine {
  display: flex;
  cursor: pointer;
  max-width: 40%;

  @include mq('tablet', 'max') {
    flex-direction: row !important;
    margin: 0;
    min-width: 100%;
  }

  .simpledates_dateview {
    @include w(50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    transition: color 0.2s linear;
    margin-top: -10px;
    color: $Bprimary;
    &:hover {
      color: $Bprimary-light;
    }
    @include mq('tablet', 'max') {
      padding-bottom: 5px;
    }
  }

  mat-form-field {
    opacity: 0;
    position: absolute;
    min-width: 100%;
  }

  .simpledates_opener {
    @include cover();
  }

  .day {
    font-size: 4vw;
    font-family: basicbold, Arial, sans-serif;
    font-weight: lighter;
    margin-bottom: 5px;
    line-height: 0.5;
    @include mq('tablet', 'max') {
      font-size: 10vw;
    }
  }

  .month {
    font-size: 1.7rem;
    font-family: special, Arial, sans-serif;
    @include mq('tablet', 'max') {
      font-size: 3vw;
    }
    @include mq('phablet', 'max') {
      font-size: 5vw;
    }
  }

  .simpledates_input {
    background: none;
    border-width: 0;
    box-shadow: none;
    color: $Btransparent;
    font-size: 0;
  }
}

.multidates_search-engine {
  position: relative;
  min-width: 135px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 10px 10px 0 20px;
  @include mq('tablet', 'max') {
    margin-top: 15px;
    padding: 0;
  }

  &:hover {
    .multidates_dateview {
      color: $Bsecondary-light;
    }
  }

  mat-form-field {
    position: absolute;
    cursor: pointer;
    opacity: 0;

    input {
      display: none;
    }
  }

  .multidates_dateview {
    transition: color 0.2s linear;
    text-align: center;

    strong {
      font-size: 1.5em;
      margin-right: 5px;
      display: block;
    }
  }
}

#passengers_search-engine {
  @include w(14%);
  text-align: center;
  font-size: 1.4rem;
  display: flex;
  justify-content: center;
  @include mq('desktop-wide', 'max') {
    @include w(10%);
  }

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 120px;

    &:hover {
      .pasajero__container,
      .pasajero {
        color: $Bprimary-light;
        border-color: $Bprimary-light;
        &:before {
          color: $Bprimary-light;
        }
      }
    }
  }

  .pasajero,
  .personas {
    width: 50px;
    height: 50px;
    margin: 0 auto 5px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: color 0.2s linear;

    &:before {
      font-size: 3rem;
      color: $Bprimary;
    }
  }

  .personas {
    display: none;

    &:before {
      margin-right: 3px;
    }
  }

  .pasajero__container {
    display: flex;
    flex-direction: column;
    transition: color 0.2s linear;
    cursor: pointer;
    line-height: 1.3;
  }

  .passenger_nav {
    margin-bottom: 15px;
    text-align: center;
    min-height: 35px;

    > div {
      margin-bottom: 10px;
    }
  }

  .passenger_list {
    display: flex;
    align-items: center;
  }

  mat-form-field {
    max-width: 150px;
  }

  mat-checkbox {
    margin: 0 10px;
    color: $Bblack;
  }

  .addpassengers {
    color: $Bgrey5;
    cursor: pointer;

    &:hover {
      color: $Bblack;
    }
  }

  @include mq('tablet', 'max') {
    margin: 15px 0 !important;
    order: 1;
    @include w(100%);
    > div {
      flex-direction: row;
      display: block;
      width: 100%;
      min-height: unset;
    }
  }
}

#options_search-engine {
  @include w(90%);
  display: flex;
  flex-wrap: wrap;
  border: solid $Bgrey7;
  border-width: 1px 0 0;
  padding: 2% 4% 0;

  .Multidestino_search & {
    @include w(40%);
    padding: 1% 0 1% 4%;
    @include mq('desktop-wide', 'max') {
      @include w(100%);
    }
  }
  @include mq('tablet', 'max') {
    margin-top: 20px !important;
    max-height: 200px;
    flex-wrap: wrap;
    flex-direction: column;
    padding: 2% 4%;
    order: 3;
    @include w(100%);
  }
  @include mq('phablet', 'max') {
    display: block;
  }

  fieldset {
    margin: 0 1.5% 5px 0;
    @include w(18%);
    font-size: 1.4rem;
    .Multidestino_search & {
      @include w(31%);
      margin: 0 0 0 1.5%;
      padding-top: 5px;
      font-size: 1.4rem;
      display: flex;
      align-items: center;
      @include mq('tablet', 'max') {
        @include w(90%);
      }
    }
    &:nth-of-type(1) {
      position: absolute;
      top: 47%;
      left: 56%;
      @include mq('tablet', 'max') {
        position: relative;
        top: unset;
        left: unset;
      }
      .Multidestino_search & {
        position: relative;
        top: unset;
        left: unset;
      }
    }
    @include mq('desktop', 'max') {
      font-size: 1.2rem;
    }
    @include mq('tablet', 'max') {
      min-height: 30px;
      @include w(48%);
      font-size: 2vw;
      margin: 10px 2% 10px 0;
      .mat-checkbox-label {
        white-space: pre-wrap;
        line-height: 17px;
      }
      .mat-checkbox-inner-container {
        margin-top: 0;
      }
    }
    @include mq('phablet', 'max') {
      @include w(100%);
      font-size: 4.5vw;
    }
  }
}

#moreopts_search-engine {
  display: flex;
  width: 90%;
  padding: 1% 4%;
  .Multidestino_search & {
    @include w(30%);
    padding: 1.2% 0 0;
    border: solid $Bgrey7;
    border-width: 1px 0 0;
    @include mq('desktop-wide', 'max') {
      @include w(100%);
      padding: 30px 0;
    }
  }
  @include mq('tablet', 'max') {
    flex-direction: column;
    margin-top: 20px !important;
    width: 100%;
    order: 2;
  }

  fieldset {
    @include w(22%);
    margin: 0 1.5%;
    &:last-child {
      margin-top: 1px;
    }
    @include mq('tablet', 'max') {
      @include w(90%);
      font-size: 1.8rem;
      margin: auto;
    }
    .Multidestino_search & {
      @include w(48.5%);
      margin: 0 0 0 1.5%;
      &:last-child {
        margin-top: 1px;
      }
      @include mq('tablet', 'max') {
        @include w(90%);
        font-size: 1.8rem;
        margin: auto;
      }
    }
  }

  .mat-select-value-text,
  label {
    //color: $Bwhite;
  }

  .mat-select-value-text,
  .mat-form-field-empty {
    font-size: 1.4rem;
  }

  .airlines_list {
    position: relative;
    padding: 5px 30px 5px 0;
    text-align: right;

    .Bless {
      position: absolute;
      right: -10px;
      top: 4px;
    }
  }
}

#submit_search-engine {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 50px;
  right: 0;
  width: 10%;
  height: calc(100% - 50px);
  padding: 0;
  border-radius: 0 20px 0 0;
  @include mq('tablet', 'max') {
    top: 0;
    width: 100%;
    font-size: 2rem;
    height: 85px;
    border-radius: 0;
    position: relative;
    order: 5;
    margin-top: 15px;
  }
}

/*___________________________________________________________ Results View ____________*/

.search-box {
  #main_search-engine {
    margin-top: 0;
  }

  &.hide {
    #main_search-engine {
      animation: toBig 0.2s linear;
    }

    #gradient_search-engine {
      background: $Btransparent;
    }
  }
}

.summary {
  position: relative;
  background: $Btransparent;
  z-index: z(level4);
  top: 0;
  height: 65px;
  max-height: 65px;
  display: block;
  width: calc(100% - 125px);
  margin: auto;
  @include mq('desktop', 'max') {
    width: calc(100% - 50px);
    margin-left: 0;
  }

  #main_search-engine {
    max-width: 1080px;
    width: 100%;
    display: flex;
    flex-direction: row;
    padding: 0;
    height: auto;
    justify-content: center;
    max-height: 65px;
    @include mq('desktop', 'max') {
      justify-content: flex-start;
    }
    @include mq('tablet', 'max') {
      display: none !important;
    }

    .idavuelta,
    .soloida {
      display: none;
    }
  }

  #nav_search-engine {
    justify-content: flex-start;
    margin: 0;
    align-items: center;
    max-height: 65px;

    span {
      display: none;
      border: 0;
      height: 55px;
      min-width: 0;
      margin-top: 5px;
      &:hover,
      &.selected {
        display: flex;
        cursor: default;
        background: none;

        &:before {
          color: $Bwhite;
        }
      }
    }
  }

  #searchEngineForm {
    align-items: center;
    height: 65px;
    width: 100%;
    border: 0;
    background: none;
    min-height: 0;
    box-shadow: none;
    &:before {
      border-width: 32px;
      left: -32px;
    }
  }

  #destdates_search-engine {
    min-width: 0;
    max-width: 100%;
    width: 80%;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .destdate_container {
      flex-direction: row;
    }
    .simpledates_dateview {
      margin: 0;
      padding-bottom: 0;
      .day {
        line-height: 1;
      }
    }
  }

  .Multidestino_search #destdates_search-engine {
    display: none;
  }

  #desdate_container_multiinfo {
    padding: 0 29px 0 20px;
    width: 800px;
    max-width: 75%;
    flex-grow: 2;
    display: flex;
    flex-wrap: wrap;
    font-size: 1.7rem;
    justify-content: flex-start;
    @include mq('desktop', 'max') {
      font-size: 1.7rem;
      padding: 0;
    }

    span {
      min-width: 33%;
      margin: 3px 0;

      &:before {
        color: $Bprimary-dark-gradient1;
      }
    }
  }

  #simpledates_search-engine {
    position: relative;
    top: -2px;
    margin: 0 0 0 20px;
    @include mq('desktop', 'max') {
      flex-direction: column;
    }

    div {
      flex-direction: row;
      max-width: 150px;
      min-width: 150px;
      @include mq('desktop', 'max') {
        height: 23px;
      }
    }

    .day {
      font-size: 2rem;
      flex-direction: row;
      margin: 0 5px;
      @extend .calendar;

      &:before {
        font-size: 1.5rem;
        position: relative;
        left: -5px;
        top: -1px;
      }

      @include mq('desktop', 'max') {
        font-size: 1.7rem;
      }
    }

    .month {
      width: 200px;
    }
  }

  #passengers_search-engine {
    min-width: 0;
    max-width: 100%;
    width: auto;

    > div {
      flex-direction: row;
      min-height: 0;
    }

    .personas {
      display: flex;
    }

    .pasajero,
    .pasajero__container {
      display: none;
    }

    .Btooltip_content {
      margin-top: -85px;
    }
  }

  #submit_search-engine {
    padding: 10px 30px;
    margin: 0 10px 0 20px;
    border: 0;
    height: 100%;
    top: 0;
    font-weight: bold;
  }

  #gradient_search-engine,
  #moreopts_search-engine,
  #options_search-engine {
    display: none;
  }
}
