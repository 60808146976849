* {
  box-sizing: border-box;
  outline: none !important;
}

html {
  font-size: 75%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

html,
body {
  top: 0;
  left: 0;
  width: 100%;
  padding: 0;
  margin: 0;
  line-height: 1;
  font-family: special, Arial, sans-serif;
}

body {

  &:before,
  &:after {
    top: 0;
    width: 100%;
    content: '';
    height: 100%;
    position: fixed;
    z-index: z(bottom);
  }

  &:before {
    background-image: url('/assets/img/logos/B_logo.svg');
    background-position: top center;
    background-size: 200% auto;
    opacity: 0.1;
  }

  &:after {
    background-color: $Bwhite;
    opacity: 0.5;
  }
}

.max-width-page {
  max-width: map_get($breakpoints, 'desktop-wide');
  margin: auto;
  min-width: 0;
  position: relative;
  overflow: hidden;
  width: 100%;
}

.max-width-box {
  @extend .max-width-page;
  overflow: visible;
}

/*--------------------------------------LINKS -----------_*/

a,
%Blink,
.link {
  color: $Bgrey5;
  text-decoration: underline;
  font-family: basicregular, Arial, sans-serif;
  cursor: pointer;
  font-size: 1.6rem;

  &:hover {
    color: $Bsecondary-dark;
    text-decoration: none;
  }
}

/*------------------------------------ MODALS & DIALOGS --------*/

.Btooltip {
  z-index: z(ceil);
  position: absolute;
}

.Btooltip_back,
.Btooltip_back:before {
  @include cover(fixed);
}

.Btooltip_back {
  background: rgba($Bprimary-dark, 0.93);
}

.Btooltip_back:before {
  content: ' ';
  opacity: 0.8;
}

.Btooltip_content {
  position: absolute;
  background: $Bwhite;
  padding: 20px;
  border-radius: 10px;

  .ko {
    top: -30px;
    right: -30px;
  }
}

.appear {
  opacity: 0;
  animation: appears 0.2s linear forwards;
}

.dialog {
  position: fixed;
  z-index: z(level5);
  background: white;
  width: 45%;
  height: 100%;
  padding: 65px 0 90px 0;
  top: 0;
  font-size: 1.6rem;
  overflow: hidden;
  max-width: 810px;

  &.show+* {
    position: fixed;
    width: 100%;
  }

  .dialog__header {
    background: $Bprimary-light;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 200px;

    &__title {
      font-family: basicbold, Arial, sans-serif;
      font-weight: lighter;
      margin-right: 20px;
      color: $Bwhite;
      padding: 5px 20px;
      font-size: 2rem;
    }
  }

  .dialog__content {
    width: 200%;
    overflow-y: scroll;
    position: relative;
    height: 100%;
    border: solid $Bgrey2;
    border-width: 0 0 1px 0;
    padding: 0 0 50px;
    background: $Bwhite;

    >* {
      display: block;
      max-width: calc(50% + 10px);
    }
  }

  .ko {
    color: $Bwhite;
    font-size: 2rem;
  }
}

.leftdialog {
  @extend .dialog;
  transition:
    left 0.5s,
    opacity 0.5s;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3);
  left: -46%;
  opacity: 0;

  &.show {
    left: 0;
    opacity: 1;
    min-width: 600px;
  }

  &.hide {
    opacity: 0;
    left: -46%;
  }
}

.backcover {
  @extend .Btooltip_back;
  z-index: z(level5);
}

.stopper-modal {
  position: fixed;
  width: calc(100% + 30px);
  height: 100%;
  background: rgba($Bprimary-dark, 0.93);
  top: 0;
  left: 0;
  z-index: z(top);
  padding: 0 !important;

  ~* {
    position: fixed;
  }

  &__scroll {
    position: relative;
    //width:calc(100% + 30px);
    width: 100%;
    overflow-y: scroll;
    height: 100%;
    padding: 0 0 60px 0;
    display: flex;
    justify-content: center;

    @include mq('tablet', 'max') {
      width: calc(100% - 30px);
    }

    &.expand-modal {
      width: 100%;
      padding-right: 15px;

      .ko {
        @include mq('tablet', 'max') {
          left: calc(98% - 5px);
        }
      }

      &__container {
        width: 85%;
      }
    }

    &__container {
      color: $Bprimary;
      text-align: center;
      width: 86%;
      margin: auto;
      max-width: 960px;
      padding: 0 0 5%;
      z-index: z(level1);
      position: absolute;
      top: 60px;

      &__title.h2withbold {
        font-size: 4rem;
        color: $Bwhite;
      }

      &__title.warning {
        font-size: 4rem;
        color: $Borange-light;
      }

      &__content {
        font-size: 1.5rem;
        line-height: 1.2;
        text-align: left;
        margin: 40px 0;
        color: $Bwhite;
        position: relative;
        overflow: hidden;
        transition: min-height 0.2s linear;
        word-break: break-word;

        &.reduced {
          max-width: 400px;
          margin-left: auto;
          margin-right: auto;

          >div {
            border-bottom: 1px solid $Bgrey7;
            padding: 10px 0;
          }
        }

        .Berror & {
          text-align: center;
          color: $Borange_light;
          font-size: 1.7rem;
        }

        &.justify {
          text-align: justify;
          padding: 0 10%;
        }

        br {
          line-height: 30px;
        }

        &__br br {
          line-height: 1;
        }

        &.white_modal {
          background: $Bwhite;
          color: $Bblack;
          padding: 5%;
          border-radius: 10px;
          max-width: 100%;
        }

        .cssload-loader__container {
          transition: height 0.5s linear;
          min-height: 340px;
        }
      }

      &__button {
        @extend .buttonbluelr;
      }
    }

    .ko {
      @extend .__ko;
      z-index: z(bottom);
    }
  }
}

.__ko {
  position: fixed;
  color: $Bgrey3;
  width: 100px;
  height: 100px;
  top: 0;
  left: calc(50% + 520px);
  display: flex;
  justify-content: center;
  align-items: center;

  @include mq('desktop-wide', 'max') {
    left: 94%;
    width: auto;
    height: 40px;
  }

  @include mq('tablet', 'max') {
    left: calc(98% - 30px);
  }

  &:hover {
    color: $Bwhite;
    transform: none;

    &:before {
      transform: scale(1.1);
      color: var(--ct-color_aux);
    }
  }

  &:before {
    @include mq('tablet-small', 'max') {
      font-size: 1.55rem;
    }
  }
}

/*--------------------------------------ICONS -----------_*/

.Bplus {
  @include circle(20px);
  color: $Bprimary-lighter;
  display: inline-flex;
  margin: 0 5px;
  cursor: pointer;
  font-size: 1.8rem;
  justify-content: center;
  align-items: center;
  background: $Btransparent;
  border: 0;
  @extend .plus;

  &:hover {
    color: $Bwhite;
    background: $Bprimary;
  }

  &.mini {
    @include circle(18px);
    font-size: 1.7rem;
  }

  &.special {
    min-width: 40px;
    min-height: 40px;
    font-size: 2.3rem;
    transition: background 0.2s linear;
    background: $Btransparent;
    color: $Bprimary-light;
    border: 1px solid $Bprimary-light;

    &:before {
      opacity: 0.5;
      transition: opacity 0.2s linear;
    }

    &:hover {
      color: $Bprimary;
      border-color: $Bprimary;

      &:before {
        opacity: 1;
      }
    }

    &.reverse {
      background: rgba($Bprimary-light, 0.5);
      color: $Bwhite;

      &:hover {
        background: $Bprimary-light;
        color: $Bprimary;
      }
    }
  }
}

.Bless {
  @extend .Bplus;
  @extend .less;

  &:before {
    letter-spacing: 0;
    font-size: 1.5rem;
    color: $Borange;
  }
}

.ko {
  position: absolute;
  top: 20px;
  right: 20px;
  color: $Bgrey5;
  font-size: 2.5rem;
  cursor: pointer;
  transition: all 0.2s linear;

  &:hover {
    color: $Bwhite;
    transform: scale(1.1);
  }
}

#navutils {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: z(top);

  i {
    @include circle(40px);
    transition: opacity 0.2s linear;
    opacity: 0.6;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.8rem;
    background: $Bprimary;
    margin: 5px 0 0;

    &:before {
      opacity: 0.4;
      color: $Bwhite;
    }

    &.hcontrast {
      opacity: 0.8;

      &:before {
        opacity: 0.7;
      }
    }

    &:hover {
      opacity: 1;

      &:before {
        opacity: 1;
      }
    }
  }

  #chaticon {
    background: $Bwhite;
    width: 35px;
    height: 35px;
    min-width: 35px;
    min-height: 35px;
    margin-left: 3px;
    margin-bottom: 15px;

    img {
      min-width: 40px;
      max-width: 40px;
      z-index: 1;
    }

    .unseen {
      position: absolute;
      left: 36px;
      top: 0;
      width: 10px;
      height: 10px;
      min-width: 10px;
      min-height: 10px;
    }
  }

  .end {
    transform: rotate(-90deg);
  }

  .ayuda {
    font-size: 4.2rem;
    background: $Btransparent;

    &:before {
      color: $Bprimary;
    }
  }
}

%roundicon {
  width: 50px;
  height: 50px;
  margin: 5px;
  color: $Btransparent;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: content-box;
  font-size: 0;

  &:before {
    font-size: 3rem;
    color: $Bwhite;
    position: absolute;
  }
}

.menuicons {
  color: $Bgrey5;
  font-size: 1.5rem;
  display: flex;
  flex-direction: column;
  max-width: 75px;
  align-items: center;
  text-align: center;
  line-height: 1;

  &:hover,
  &:focus,
  &.active {
    color: $Bprimary-lighter;

    i.m_icon:before {
      color: $Bwhite;
    }

    i.cancelar:before {
      color: $Berror-dark;
    }
  }

  i {
    @extend %roundicon;
  }

  i.cancelar {
    color: $Berror-light;

    &:before {
      color: $Berror-light;
    }

    &:hover,
    &:focus,
    &.active {
      &:before {
        color: $Berror-dark;
      }
    }
  }

  &.disabled {
    color: $Bgrey5;

    &:hover {
      color: $Bgrey5;
    }

    i.m_icon,
    i.m_icon:hover,
    i.m_icon:focus,
    i.m_icon.active {
      cursor: default;
      opacity: 0.7;
      border: 0;

      &:before {
        transform: scale(1);
        color: $Bgrey5;
      }
    }
  }
}

i.m_icon {
  cursor: pointer;
  transition: background 0.2s linear;
  height: 35px;

  &:before {
    color: $Bprimary-light;
    transition: color 0.2s linear;
  }

  &:hover,
  &:focus,
  &.active {
    color: $Bwhite;

    &:before {
      color: $Bwhite;
      transform: scale(1.1);
    }
  }

  &.active.selected {
    color: $Bwhite;
  }
}

.menuinnericons {
  @extend .menuicons;
  position: absolute;
  top: -35px;
  right: -35px;
  font-size: 1.3rem;

  &:hover,
  &:focus,
  &.active {
    color: $Bblack;
  }

  i {
    max-width: 40px;
    max-height: 40px;
    min-width: 40px;
    min-height: 40px;
  }
}

.soloida,
.idavuelta {
  @extend %roundicon;
  position: absolute;
  border: 2px solid $Bwhite;
}

span.soloida {
  transition: margin 0.1s linear;
  margin: 1rem 0 0 7.5rem;
}

.-icon-hide {
  color: $Bwhite;

  .menosopciones {
    display: none;

    th:hover & {
      position: absolute;
      right: -20px;
    }
  }
}

.menosopciones.arrow-rotate {
  transform: rotate(180deg);
  transform-origin: 50% 47%;
}

.msgbullet {
  background: $Bfourth;
  @include circle(18px);
  font-family: basicbold, Arial, sans-serif;
  font-weight: lighter;
  color: $Bblack;
  animation: pulsebullet 1s alternate infinite;
  font-size: 1.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

/*--------------------------------------BOX -----------_*/

%Bstfigure {
  img {
    width: 100%;
  }
}

%Bborder {
  content: ' ';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 0;
  border-right: 30px solid transparent;
  border-top: 30px solid transparent;
}

%removeBdr {
  position: relative;
  bottom: unset;
  left: unset;
  width: unset;
  height: unset;
  border: 0;
}

.Bborder-y {
  // @extend %Bborder; border-left: 30px solid $Bfourth; border-bottom: 30px solid $Bfourth;
}

.Bborder-blr {
  //@extend %Bborder; border-left: 50px solid $Bprimary; border-bottom: 50px solid $Bprimary;
}

.Bborder-bl {
  //@extend %Bborder; border-left: 50px solid $Bthird; border-bottom: 50px solid $Bthird;
}

.Bborder-b {
  // @extend %Bborder; border-left: 30px solid $Bsecondary; border-bottom: 30px solid $Bsecondary;
}

.Bborder-bd {
  // @extend %Bborder; border-left: 50px solid $Bprimary-dark; border-bottom: 50px solid $Bprimary-dark;
}

.Bborder-gr {
  // @extend %Bborder; border-left: 50px solid $Bgrey2; border-bottom: 50px solid $Bgrey2;
}

.Bbacky {
  background-color: $Bsecondary;
}

.Bbackblr,
.Simple {
  background-color: $Bsecondary;
}

.Bbackbl,
.Calendario {
  background-color: rgba($Bsecondary-lighter, 0.4);
}

.Bbackb,
.Multidestino {
  background-color: $Bsecondary-dark;
}

.Bbackbd {
  background-color: $Bprimary-dark;
}

.returnlink {
  position: absolute;
  top: 30px;
  left: 30px;
  text-transform: uppercase;
  @extend %Blink;
}

.brand_header {
  text-align: center;
  background-color: $Btransparent;

  &.Multidestino {
    background: $Bsecondary-dark;

    .h1withbold {
      color: $Bsecondary-lighter;
    }
  }

  &.Calendario {
    background-color: rgba($Bsecondary-light, 0.2);
  }

  .h1withbold {
    margin: 0;
    font-size: 4.5rem;
    padding: 15px 0;
    color: $Bsecondary-dark;

    @include mq('tablet', 'max') {
      font-size: 2.5rem;
    }
  }
}

.orange-pill {
  background: $Borange-light;
  padding: 5px 18px;
  border-radius: 10px;
  display: inline-block;
  font-family: basicbold, Arial, sans-serif;
  font-weight: lighter;
  color: $Bblack;
  font-size: 1.1em;
}

/*--------------------------------------TEXT -----------_*/

.logotext {
  font-family: basicbold, Arial, sans-serif;
  font-weight: lighter;
  padding: 0 10px;
  color: $Bprimary-dark;

  span,
  i {
    background: $Bsecondary;
    padding: 1px 10px;
    border: 2px solid $Bprimary-dark;
  }

  span:nth-of-type(1) {
    margin: 10px 0 10px 10px;
    border-width: 2px 0 2px 2px;
  }

  span:nth-of-type(2) {
    margin: 10px 10px 10px 0;
    border-width: 2px 2px 2px 0;
  }

  i {
    padding: 9px 10px 5px;
    top: 2px;
    position: relative;

    &:before {
      transform: rotate(-45deg);
      display: inline-block;
    }
  }
}

h1,
h2,
h3,
h4,
h5 {
  font-family: basicbold, Arial, sans-serif;
  font-weight: lighter;
}

.h1withbold {
  font-size: 5rem;
  font-family: basiclight, Arial, sans-serif;
  font-weight: lighter;
  margin: 0 0 10px 0;
  color: $Bprimary;

  strong {
    font-family: basicbold, Arial, sans-serif;
    font-weight: lighter;
  }
}

.h2withbold {
  @extend .h1withbold;
  font-size: 3.5rem;
  color: $Bblack;
}

.h3withbold {
  @extend .h1withbold;
  font-family: basicregular, Arial, sans-serif;
  font-size: 2.5rem;
  color: $Bprimary;
}

.h4withbold {
  @extend .h1withbold;
  font-size: 1.8rem;
  font-family: basicregular, Arial, sans-serif;
  color: $Bprimary-lighter;

  .Berror {
    position: relative;
    bottom: 0;
    display: block;
    padding: 5px 0;
    font-size: 0.8em;
  }
}

.Bwarning {
  color: $Borange;
}

.Berror {
  color: $Berror;
  padding: 20px;
  display: inline-block;
}

%Btext_light {
  font-family: basiclight, Arial, sans-serif;
  font-size: 1.6rem;
  max-width: 700px;
}

%Btext_reg {
  font-family: basicregular, Arial, sans-serif;
  font-size: 1.6rem;
  max-width: 700px;
}

.tstop {
  font-family: basicregular, Arial, sans-serif;
  color: $Bviolet-dark;
  padding: 2px 0;
  line-height: 1;
  font-size: 0.9em;
}

.leftdialog {
  padding-bottom: 20px;

  .extendedinfo__container__div {
    position: absolute;
    top: 180px;
    bottom: 25px;
    display: block;

    .extendedinfo__container__code {
      max-height: 100%;
      max-width: 760px;
    }
  }
}

sbmn-flights-offers .banner_home_footer {
  display: none;
}

/*- Theme ------*/
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');

:root {
  --ct-color_main: #040438;
  --ct-color_main_alt: #e8eef1;
  --ct-color_aux: #15e581;
  --ct-color_aux_alt: #ffffff;
  --ct-color_link: #040438;
  --ct-color_link_alt: #ffffff;
  --ct-color_link_hover: #ffae00;
  --ct-color_link_hover_alt: #fff;
  --ct-font-main_family: 'Manrope', sans-serif;
}

html {
  font-size: 0.65em !important;
  font-family: var(--ct-font-main_family) !important;
  line-height: 1.2 !important;
}

body {
  font-family: var(--ct-font-main_family) !important;
  line-height: 1.2 !important;
  background-color: #fff !important;

  &::after {
    background-color: #f3f6f8 !important;
  }

  &::before {
    background-image: none !important;
  }
}

/*font - generic*/
a,
form *,
.returnlink,
.login-reset-nav,
.link,
sbmn-flights-messages section div,
sbmn-flights-offers div,
#gradient_search-engine.Simpleborder span,
#gradient_search-engine.Calendarioborder span,
#gradient_search-engine.Multidestinoborder span,
#gradient_search-engine.Simpleborder strong,
#gradient_search-engine.Calendarioborder strong,
#gradient_search-engine.Multidestinoborder strong,
.h1withbold strong,
.h4withbold strong,
.h3withbold strong,
.cms__basicinfo__container__content>span>strong strong,
.h2withbold strong,
sbmn-flights-filters section span,
.Bbutton,
.buttonred,
.buttonyellow,
.buttontransparent,
.buttontransparentb,
.buttonwhite,
.buttonbluelr,
.stopper-modal__scroll__container__button,
.buttonblue,
.fsummary__content * {
  font-family: var(--ct-font-main_family) !important;
}

/* General */
h1,
h2,
h3,
h4,
h5 {
  font-family: var(--ct-font-main_family) !important;
  font-weight: 400 !important;
}

.h1withbold,
.h2withbold,
.h3withbold,
.h4withbold {
  font-size: 3.2rem !important;
  color: var(--ct-color_aux) !important;

  strong {
    font-weight: 400 !important;
    color: var(--ct-color_main) !important;
  }
}

.buttonyellow,
.buttonbluelr,
.stopper-modal__scroll__container__button {
  font-weight: 500;
  text-transform: none;
  letter-spacing: 0.1em;
  color: var(--ct-color_aux_alt);
  border: 0;
  border-color: var(--ct-color_aux);
  border-radius: 3px;
  background-color: var(--ct-color_aux);

  &:hover {
    border-color: var(--ct-color_link_hover_alt);
    color: var(--ct-color_link_hover_alt);
    background-color: var(--ct-color_link_hover);
    box-shadow: none;
  }
}

.buttonbluelr,
.stopper-modal__scroll__container__button {
  font-size: 0.95em;
}

/* Home */
/* Estructura*/
sbmn-flights-dashboard {
  .max-width-page> :first-child {
    order: 1;
    display: inline-block;
  }

  .max-width-page> :nth-child(2) {
    order: 2;
    top: 0;
    margin: 0 0 50px;
  }

  .max-width-page> :nth-child(3) {
    order: 3 !important;
    display: inline-block;
    width: 100%;
    max-width: initial;
    margin: 0;
    padding: 0;
    border-radius: 0;
  }

  .max-width-page> :nth-child(5) {
    order: 5 !important;
  }

  sbmn-flights-search-engine {
    max-width: none !important;
  }

  sbmn-flights-welcome {
    max-height: 440px !important;
    min-height: 440px !important;

    &::before {
      content: none !important;
    }

    figure,
    figcaption {
      position: relative;
    }

    figcaption h2 {
      padding: 5px;
      font-size: 2.5vw;
    }

    .owl-theme .owl-dots {
      position: absolute;
      right: 0;
      left: 0;
      max-width: none;
      z-index: 1;
      top: 0;
    }

    .owl-dot {
      span {
        border-color: rgba(255, 255, 255, 0.5) !important;
      }

      &.active span {
        border-color: rgba(255, 255, 255, 0.5) !important;
        background: rgba(255, 255, 255, 0.5) !important;
      }
    }
  }
}

sbmn-flights-highlight {
  section {
    width: 100%;
    height: auto;
    padding: 0.5em;
    flex-direction: unset !important;
    background: none !important;
  }

  button.buttonbluelr {
    margin: 0 0 0 10px;
  }
}

sbmn-flights-offers {
  width: 66%;
  max-width: 950px !important;
  display: inline-block;
}

sbmn-flights-messages {
  width: 33%;
  max-width: 464px !important;
  flex-basis: auto !important;

  owl-carousel-o {
    height: auto;
  }

  .owl-theme .owl-dots {
    bottom: -40px !important;
  }

  .owl-dot.active span {
    background: var(--ct-color_main) !important;
  }
}

/* Estilos */
#main_header {
  box-shadow: 1px 0 5px 0 rgba(0, 0, 0, 62) !important;
  background-color: var(--ct-color_main);

  a {
    text-transform: none !important;
    color: var(--ct-color_main_alt) !important;

    &:hover {
      color: var(--ct-color_link_hover) !important;
    }

    &.active {
      color: var(--ct-color_aux) !important;
    }

    &::before {
      display: none;
    }

    &.header__balance {
      margin-right: 0 !important;
      font: 700 2rem var(--ct-font-main_family) !important;
      text-align: right;
      color: var(--ct-color_aux) !important;
      border: 0 !important;

      span {
        font: 500 1.1rem var(--ct-font-main_family) !important;
        text-transform: lowercase;
        color: var(--ct-color_main_alt);
      }

      &:hover,
      &:hover span {
        color: var(--ct-color_link_hover) !important;
      }
    }
  }

  figure img {
    max-height: 60px;
  }

  .poweroff {
    top: 0;
    bottom: 0;
    right: 0;
    background-color: transparent;
    transition: all 0.05s;

    &::before {
      font-size: 2rem !important;
      color: var(--ct-color_aux) !important;
    }

    &:hover {
      color: var(--ct-color_link_hover) !important;
      background-color: transparent;
    }
  }

  .poweroff:hover::before {
    color: var(--ct-color_link_hover) !important;
  }

  .mobile_menu_container__burger {
    top: 18px;
  }

  .mobile_menu_container__burger__bar1,
  .mobile_menu_container__burger__bar2,
  .mobile_menu_container__burger__bar3 {
    width: 20px;
    height: 3px;
    margin: 2px 0;
    background-color: var(--ct-color_aux) !important;
  }

  .mobile_menu_container__burger.-change .mobile_menu_container__burger__bar1,
  .mobile_menu_container__burger.-change .mobile_menu_container__burger__bar2,
  .mobile_menu_container__burger.-change .mobile_menu_container__burger__bar3 {
    margin: 4px;
  }
}

#main_footer {
  background: var(--ct-color_main) !important;
}

sbmn-flights-highlight {
  box-shadow: 1px 0 5px 0 rgba(128, 138, 153, 62) !important;
  z-index: 1;
  padding: 10px;
  background: var(--ct-color_aux);
  background: linear-gradient(135deg, var(--ct-color_aux) 0%, var(--ct-color_aux) 100%);

  section {
    &>div {
      font: 500 1.55rem var(--ct-font-main_family) !important;
    }

    &>.buttonbluelr {
      padding: 10px 20px !important;
      font: 400 1.55rem var(--ct-font-main_family) !important;
      text-transform: none;
      border: 0;
      border-radius: 20px;
      color: var(--ct-color_main_alt);
      background: var(--ct-color_main);
    }

    &>.buttonbluelr:hover {
      border: 0;
      color: var(--ct-color_link_hover_alt);
      background: var(--ct-color_link_hover);
    }
  }
}

sbmn-flights-messages,
sbmn-flights-offers {
  box-shadow: none !important;
  background: none !important;
}

sbmn-flights-offers {
  #list_offers {
    div {
      text-transform: none !important;
      box-shadow: 0 0 5px 3px rgba(173, 204, 224, 0.3);
      border-radius: 2px;
      background: #fff;

      &:hover {
        color: var(--ct-color_link_hover_alt);
        background: var(--ct-color_link_hover) !important;

        .desc,
        .price {
          color: var(--ct-color_link_hover_alt);
        }

        .idavuelta::before,
        .soloida::before {
          color: rgba(255, 255, 255, 0.4);
        }
      }
    }

    .desc {
      color: var(--ct-color_main);
    }

    .price {
      position: relative;
      top: -2px;
      font-size: 1.2em;
      font-weight: 700;
      color: var(--ct-color_aux);
    }

    .idavuelta,
    .soloida {
      left: 5px;
      top: 13px;
      margin: 0;
      background: none;
    }

    .idavuelta::before,
    .soloida::before {
      font-size: 19px;
      color: var(--ct-color_aux);
    }
  }
}

sbmn-flights-messages section {
  position: relative;
  margin: 0 0 10px !important;
  padding: 5px 65px 25px 25px !important;
  font-size: 0.8em;
  border-radius: 3px;
  box-shadow: 0 0 5px 3px rgba(173, 204, 224, 0.3);
  transition: all 0.1;
}

sbmn-flights-messages section i.ng-star-inserted,
sbmn-flights-messages section img.ng-star-inserted {
  display: block;
  position: absolute;
  top: 50%;
  right: 0;
  left: auto;
  margin-top: -25px;
  border: 0;
}

sbmn-flights-messages .link {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-indent: -9999px;
  margin: 0 !important;
  background: none;
  transition: 0.15s all;
}

sbmn-flights-messages section div {
  font-size: 1.3rem !important;
  line-height: 1.9rem;
}

sbmn-flights-messages section._boomerang div {
  color: #f3f6f8;
}

sbmn-flights-messages section._boomerang {
  background: var(--ct-color_main);
}

sbmn-flights-messages section._boomerang h2 {
  color: #f3f6f8;
}

sbmn-flights-messages section._avisos {
  background: #f3ebde;
}

sbmn-flights-messages section._avisos h2 {
  color: #736348;
}

sbmn-flights-messages section._vuelos {
  background: #dee8f3;
}

sbmn-flights-messages section._vuelos h2 {
  color: #1e3b5b;
}

sbmn-flights-messages section._info {
  background: #e3f5ff;
}

sbmn-flights-messages section._info h2 {
  color: #1e3b5b;
}

sbmn-flights-messages i.megafono::before {
  color: #ddd1bd !important;
}

sbmn-flights-messages i.avion::before {
  color: #bdcddf !important;
}

sbmn-flights-messages i.info3::before {
  color: #bed6e5 !important;
}

sbmn-flights-messages section:hover {
  background: var(--ct-color_link_hover) !important;
}

sbmn-flights-messages section:hover h2,
sbmn-flights-messages section:hover div {
  color: var(--ct-color_link_hover_alt) !important;
}

/* Global */
.mat-checkbox,
.mat-select,
.mat-input-element {
  font-family: var(--ct-font-main_family) !important;
}

.mat-option-text {
  text-transform: none;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: var(--ct-color_aux);
}

.mat-form-field-label {
  font-weight: 700 !important;
  font-size: 16px !important;
}

.mat-form-field.mat-focused .mat-form-field-label {
  color: var(--ct-color_main);
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled),
.mtamain__logo__figcaption:before,
.mtamain__logo__nologo:before {
  color: var(--ct-color_aux);
}

.mat-option.mat-active,
.mat-option:hover:not(.mat-option-disabled),
.mat-option:focus:not(.mat-option-disabled) {
  background: rgba(21, 229, 129, 0.18);
}

.mat-slider.mat-primary .mat-slider-track-fill,
.mat-slider.mat-primary .mat-slider-thumb,
.mat-slider.mat-primary .mat-slider-thumb-label,
.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple,
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: var(--ct-color_aux);
}

.mta__menu,
.mybookings__legend,
.mymessages__container__legend {
  max-width: 1382px;
  margin: 0 auto 15px !important;
  padding: 10px !important;
  text-align: left !important;
  background: #dbeaf5;
}

.mta__menu__link,
.mta__menu__link i {
  color: var(--ct-color_main);
  border-color: var(--ct-color_main);
}

.mta__menu__link.active,
.mta__menu__link:hover i,
.mta__menu__link.active i,
.mta__submenu__link:focus,
.mta__submenu__link.selected {
  color: var(--ct-color_aux);
}

.mta__menu__link:hover,
.mta__menu__link:hover i,
.mta__submenu__link:hover,
.mtamain__logo__nologo:hover:before {
  color: var(--ct-color_link_hover);
}

.dialog .dialog__header,
.leftdialog .dialog__header {
  background: var(--ct-color_main);
}

/* Searcher */
sbmn-flights-search-engine {
  margin-bottom: 50px;
}

#main_search-engine {
  text-transform: none !important;
}

#nav_search-engine {
  margin: 0 auto !important;
}

#nav_search-engine span {
  min-width: 75px !important;
  min-height: 75px;
  margin: 0 5px !important;
  border-radius: 4px 4px 0 0;
  border: 0 !important;
  background: #c8cdd0 !important;
}

#nav_search-engine span::before {
  opacity: 1 !important;
  color: #666 !important;
}

#nav_search-engine span.selected {
  opacity: 1;
  background: #fff !important;
}

#nav_search-engine span.selected::before {
  color: var(--ct-color_aux) !important;
}

#nav_search-engine span:hover {
  opacity: 1;
}

#searchEngineForm {
  min-height: 215px !important;
  padding: 20px;
  font-family: var(--ct-font-main_family) !important;
  box-shadow: 0 0 5px 3px rgba(173, 204, 224, 0.3) !important;
}

#searchEngineForm #passengers_search-engine .pasajero::before,
#searchEngineForm #passengers_search-engine .personas::before {
  color: #96b8d3;
}

#searchEngineForm #passengers_search-engine span {
  color: var(--ct-color_aux);
}

#searchEngineForm #passengers_search-engine:hover .pasajero::before,
#searchEngineForm #passengers_search-engine:hover .personas::before,
#searchEngineForm #passengers_search-engine span {
  color: var(--ct-color_main);
}

#searchEngineForm #destdates_search-engine .idavuelta:before,
#loading-data .idavuelta:before,
#loading-data .soloida:before {
  color: var(--ct-color_aux);
}

#destdates_search-engine {
  flex-direction: column;
}

#searchEngineForm #destdates_search-engine>section {
  width: 100%;
  min-width: 100%;
  justify-content: flex-start;
}

.Multidestino_search #destdates_search-engine mat-form-field {
  margin: 0 !important;
  padding: 0 20px !important;
}

#searchEngineForm #destdates_search-engine .destdate_container {
  padding: 15px 0 0 0;
}

#simpledates_search-engine .simpledates_dateview {
  padding: 0 25px;
  flex-direction: row !important;
  justify-content: left !important;
}

#searchEngineForm #destdates_search-engine .simpledates_dateview .day,
#searchEngineForm #destdates_search-engine .simpledates_dateview .month,
#searchEngineForm .multidates_search-engine .multidates_dateview {
  font-family: var(--ct-font-main_family) !important;
  font-weight: 300 !important;
  color: var(--ct-color_aux);
}

#searchEngineForm #destdates_search-engine .simpledates_dateview:hover .day,
#searchEngineForm #destdates_search-engine .simpledates_dateview:hover .month,
#searchEngineForm .multidates_search-engine:hover .multidates_dateview {
  color: var(--ct-color_main);
}

#searchEngineForm #destdates_search-engine .simpledates_dateview .day {
  font-size: 3.5rem;
  padding-right: 8px;
}

#searchEngineForm #options_search-engine {
  max-height: none !important;
  background: var(--ct-color_main);
}

#searchEngineForm #options_search-engine fieldset .mat-checkbox-frame {
  border-color: var(--ct-color_aux) !important;
}

#searchEngineForm #options_search-engine .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background,
#searchEngineForm #options_search-engine .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: var(--ct-color_aux);
}

#searchEngineForm #options_search-engine label {
  color: #f3f6f8;
}

#searchEngineForm #moreopts_search-engine {
  padding: 25px 0 0;
}

.Multidestino_search #searchEngineForm #moreopts_search-engine {
  min-width: 60%;
  max-width: 60%;
  width: 60%;
}

.Multidestino_search #destdates_search-engine .addRoute {
  opacity: 1 !important;
  font-family: var(--ct-font-main_family) !important;
  font-weight: 500 !important;
  color: var(--ct-color_aux);
}

.Multidestino_search #destdates_search-engine .addRoute:hover {
  color: var(--ct-color_main);
}

.Bplus,
.Bless {
  color: var(--ct-color_aux);
  background-color: transparent;
}

.Multidestino_search #destdates_search-engine .addRoute:hover .Bplus,
.Multidestino_search #destdates_search-engine .addRoute:hover .Bless {
  color: var(--ct-color_link_hover);
  background: transparent;
}

#searchEngineForm #submit_search-engine {
  border: 0;
  border-radius: 2px;
  color: var(--ct-color_aux_alt);
  background: var(--ct-color_aux);
  transition: all 0.15s;
}

#searchEngineForm #submit_search-engine:hover {
  color: var(--ct-color_link_hover_alt);
  background: var(--ct-color_link_hover);
}

sbmn-flights-psg-manager .stopper-modal.modal_psg_manager h2 {
  color: var(--ct-color_main) !important;
}

.stopper-modal.modal_psg_manager .psg_manager__container__box__title,
.stopper-modal.modal_psg_manager .psg_manager__container__box__legend {
  color: var(--ct-color_main);
}

.stopper-modal.modal_psg_manager .buttontransparent {
  min-width: 120px;
  padding: 10px 20px;
  font: 500 1.55rem var(--ct-font-main_family) !important;
  text-transform: none;
  border: 0;
  border-radius: 20px;
  background: #fff;
}

.stopper-modal.modal_psg_manager .buttontransparent:hover {
  color: #fff;
  background: var(--ct-color_main);
}

/* Listado */
sbmn-flights-results header.sticky,
sbmn-flights-calendar header.sticky {
  height: 60px !important;
}

sbmn-flights-results header.sticky.hhide,
sbmn-flights-calendar header.sticky.hhide {
  background-color: var(--ct-color_aux);
}

.summary #nav_search-engine span {
  min-width: 40px !important;
  color: rgba(255, 255, 255, 0.8) !important;
  background: none !important;
}

.summary #nav_search-engine span::before {
  transform: scale(0.8) !important;
  color: rgba(255, 255, 255, 0.54) !important;
}

.summary #searchEngineForm {
  align-items: center;
  min-height: 60px !important;
  padding: 0;
  border: 0;
}

.summary #searchEngineForm #destdates_search-engine,
.summary #searchEngineForm #destdates_search-engine>section {
  width: auto;
  max-width: none;
  min-width: auto;
  flex-direction: row;
}

.summary #submit_search-engine {
  position: relative;
  top: auto !important;
  left: auto !important;
  right: auto !important;
  width: 80px;
  height: auto !important;
  margin: 0 !important;
  padding: 10px 20px !important;
  font: 500 1.55rem var(--ct-font-main_family) !important;
  text-transform: none;
  border: 0;
  border-radius: 20px;
  background: var(--ct-color_main) !important;
}

.summary #searchEngineForm #destdates_search-engine .simpledates_dateview .day,
.summary #searchEngineForm #destdates_search-engine .simpledates_dateview .month,
.summary #searchEngineForm .multidates_search-engine .multidates_dateview,
.summary #searchEngineForm #passengers_search-engine .personas,
.summary #searchEngineForm #passengers_search-engine .pasajero::before,
.summary #searchEngineForm #passengers_search-engine .personas::before,
.summary .mat-input-element,
.summary #desdate_container_multiinfo,
.summary #desdate_container_multiinfo span::before,
.summary .mat-form-field .mat-form-field-label {
  color: #fff;
}

.summary #desdate_container_multiinfo {
  font-weight: 600;
}

.summary .destdate_container .mat-input-element {
  color: #000;
}

.summary #searchEngineForm #destdates_search-engine .simpledates_dateview:hover .day,
.summary #searchEngineForm #destdates_search-engine .simpledates_dateview:hover .month,
.summary #searchEngineForm .multidates_search-engine:hover .multidates_dateview,
.summary #searchEngineForm #passengers_search-engine .personas:hover,
.summary #searchEngineForm #passengers_search-engine .personas:hover::before {
  color: var(--ct-color_main);
}

.summary #searchEngineForm #passengers_search-engine .personas {
  font-size: 1.8em;
}

sbmn-flights-filters section h3:before {
  font-size: 1.6rem;
  color: #9cb5d3;
}

@media (min-width: 996px) and (max-width: 1200px) {
  .summary #searchEngineForm #destdates_search-engine>section {
    flex-direction: column;
  }

  .summary #destdates_search-engine .simpledates_dateview {
    padding: 0;
  }

  .summary #simpledates_search-engine .day {
    font-size: 1.4em !important;
  }

  .summary #simpledates_search-engine .day::before {
    display: none;
  }
}

sbmn-flights-results>header.hsummary,
sbmn-flights-calendar>header.hsummary {
  box-shadow: 1px 0 5px 0 rgba(128, 138, 153, 62) !important;
  background: var(--ct-color_aux) !important;
  background: linear-gradient(135deg, var(--ct-color_aux) 0%, var(--ct-color_aux) 100%) !important;
}

sbmn-flights-results .menosopciones,
sbmn-flights-calendar .menosopciones {
  color: var(--ct-color_aux);
  border-radius: 50%;
}

sbmn-flights-search-engine.search-box #searchEngineForm #destdates_search-engine .destdate_container {
  padding-top: 15px;
}

.fresult_header {
  margin: 20px 0;
}

.fresult_header h1.h1withbold {
  color: var(--ct-color_main) !important;
}

sbmn-flights-results .fresult_header .filtrar,
sbmn-flights-calendar .fresult_header .filtrar,
.mybookings__header__icon.filtrar,
.mymessages__header__icon,
.mymessages__header .Bplus,
.mymessages__header .Bless,
.mta__header__filter-button,
.Bplus.special,
.special.Bless,
sbmn-flights-results .filtrar,
sbmn-flights-calendar .filtrar {
  width: 38px;
  height: 38px;
  padding: 0;
  font-size: 20px;
  color: var(--ct-color_aux) !important;
  border: 0;
  border-radius: 19px;
  transition: 0.15s all;
  background: none !important;
}

.mybookings__header__icon::before {
  color: var(--ct-color_aux) !important;
  border: 0 !important;
}

.mymessages__header__icon::before,
.mta__header__filter-button::before,
.Bplus.special,
.special.Bless::before {
  border: 0 !important;
}

sbmn-flights-results .fresult_header .filtrar:hover,
sbmn-flights-calendar .fresult_header .filtrar:hover,
.mybookings__header__icon.filtrar:hover,
.mymessages__header__icon:hover,
.mymessages__header .Bplus:hover,
.mymessages__header .Bless:hover,
.mta__header__filter-button:hover,
.Bplus.special:hover,
.special.Bless:hover,
sbmn-flights-results .filtrar:hover,
sbmn-flights-calendar .filtrar:hover {
  color: var(--ct-color_link_hover) !important;
  background: none;
}

sbmn-pagination .pagination__info {
  color: var(--ct-color_main);
}

sbmn-pagination .pagination__info strong {
  font-weight: 600;
}

sbmn-pagination .pagination .pagination__container__arrow {
  opacity: 1;
  width: 38px;
  height: 38px;
  color: var(--ct-color_aux);
  background: none;
}

sbmn-pagination .pagination .pagination__container__arrow:hover {
  color: var(--ct-color_link_hover);
  background: none;
}

#main_header .mobile_menu_container__sidenav {
  box-shadow: 1px 0 5px 0 rgba(128, 138, 153, 62);
  border: 0;
  background: var(--ct-color_main) !important;
}

section.fresults_filters,
.mybookings__filters,
.mymessages__filters,
.mtausers__filters,
.mtabalance__filters {
  box-shadow: 1px 0 15px 5px rgba(173, 204, 224, 0.47);
  border: 0;
  background: #fff !important;
}

#fresults_list .fresult_scheme {
  border-radius: 2px;
  box-shadow: 0 0 5px 3px rgba(173, 204, 224, 0.47);
  background: #fff;
}

#fresults_list .fresult_schemedata,
#fresults_list .fresult_schemeprice {
  box-shadow: none;
}

.fresult_schemedata .fresult_headerscheme {
  min-height: 46px;
  padding-top: 3px;
  border: 0;
  background: var(--ct-color_main);
}

.fresult_schemedata .fresult_headerscheme i {
  min-width: 26px;
  margin-left: 5px;
  padding: 0;
  border: 0;
}

.fresult_schemedata .fresult_headerscheme i::before {
  font-family: 'icons' !important;
  font-size: 1.45em;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  color: #abbec9;
}

.fresult_schemedata .fresult_headerscheme i.ida::before {
  content: '\e902' !important;
}

.fresult_schemedata .fresult_headerscheme i.vuelta::before {
  content: '\e902' !important;
  transform: rotate(180deg);
}

.fresult_schemedata .fresult_headerscheme p {
  position: relative;
  font: 700 1.4rem var(--ct-font-main_family) !important;
  color: #fff;
}

.fresult_schemedata .fresult_headerscheme p span {
  position: absolute;
  top: -1px;
  right: -100px;
  font: 300 1.4rem var(--ct-font-main_family) !important;
  color: #fff;
}

.fresult_schemedata .fresult_route {
  border-color: #d9e2e8;
}

.fresult_schemedata .fresult_route .fresults_routesummary {
  padding-top: 2px;
  font-family: var(--ct-font-main_family);
  font-size: 1.2em;
  font-weight: 300;
  letter-spacing: -0.005em;
}

.fresult_schemedata .fresult_route .fresults_routesummary span {
  opacity: 0.82;
  padding-top: 5px;
  font: 400 1.1rem var(--ct-font-main_family) !important;
  text-transform: none;
}

.fresult_schemedata .fresult_route .fresults_stopover {
  font-family: var(--ct-font-main_family);
  color: var(--ct-color_main);
}

.fresult_schemedata .fresult_route .fresults_routeinfo,
.fresult_schemedata .fresults_schemeicons .fresults_class {
  width: 38px;
  min-width: 38px;
  height: 38px;
  min-height: 38px;
}

.fresult_schemedata .fresult_route .fresults_routeinfo::before {
  content: '';
}

.fresult_schemedata .fresult_route .fresults_routeinfo i {
  margin: 0 0 0 4px;
  font-size: 1.7rem;
}

.fresult_schemedata .fresults_schemeicons .fresults_class {
  margin-top: 10px;
  margin-left: 10px;
  border-radius: 3px;
}

.fresult_schemedata .fresults_schemeicons .butaca .fresults_seats_text,
.fresult_schemedata .fresults_schemeicons .fresults_baggagetxt,
.fresult_schemedata .fresults_schemeicons .fresults_class {
  top: -2px;
  font-family: var(--ct-font-main_family);
  font-size: 1em;
  font-weight: 500;
}

/*Colors*/
/* - Defecto */
.fresult_schemedata .fresult_route .fresults_routesummary {
  color: var(--ct-color_main);
}

.fresult_schemedata .fresult_route .fresults_routesummary span {
  color: var(--ct-color_main);
}

.fresult_schemedata .fresults_schemeicons .butaca::before,
.fresult_schemedata .fresults_schemeicons .maleta::before {
  color: #dbeaf5;
}

.fresult_schemedata .fresult_route .fresults_routeinfo,
.fresult_schemedata .fresults_schemeicons .fresults_class {
  background: #dbeaf5;
}

.fresult_schemedata .fresult_route .fresults_routeinfo i {
  color: var(--ct-color_aux);
}

.fresult_schemedata .fresults_schemeicons .butaca .fresults_seats_text,
.fresult_schemedata .fresults_schemeicons .fresults_baggagetxt,
.fresult_schemedata .fresults_schemeicons .fresults_class {
  color: var(--ct-color_main);
}

/* - Hover */
.fresult_route:hover .fresults_routesummary {
  color: var(--ct-color_main) !important;
}

.fresult_route:hover .fresults_routesummary span {
  color: var(--ct-color_main);
}

.fresult_schemedata .fresult_route:hover {
  background: #e1e9e5;
}

.fresult_route:hover .fresults_state {
  background: #e1e9e5 !important;
}

.fresult_route:hover .fresults_schemeicons .butaca::before,
.fresult_route:hover .fresults_schemeicons .maleta::before {
  color: var(--ct-color_aux) !important;
}

.fresult_route:hover .fresults_routeinfo,
.fresult_route:hover .fresults_schemeicons .fresults_class {
  background: var(--ct-color_aux);
}

.fresult_schemedata .fresult_route:hover .fresults_routeinfo i {
  color: #e1e9e5;
}

.fresult_route:hover .fresults_schemeicons .butaca .fresults_seats_text,
.fresult_route:hover .fresults_schemeicons .fresults_baggagetxt,
.fresult_route:hover .fresults_schemeicons .fresults_class {
  color: #03253e;
}

/* - Activo */
.fresult_route.selected .fresults_routesummary {
  color: #354b39 !important;
}

.fresult_route.selected .fresults_routesummary span {
  color: var(--ct-color_main);
}

.fresult_schemedata .fresult_route.selected {
  background: var(--ct-color_aux);
}

.fresult_schemedata .fresult_route.selected::before {
  top: 25px;
  right: 17px;
  color: var(--ct-color_aux_alt);
}

.fresult_route.selected .fresults_state {
  border: 1px #ade1b7 solid;
  background: #ddefe6 !important;
}

.fresult_route.selected .fresults_schemeicons .butaca::before,
.fresult_route.selected .fresults_schemeicons .maleta::before {
  color: #c4dfc9 !important;
}

.fresult_route.selected .fresults_routeinfo,
.fresult_route.selected .fresults_schemeicons .fresults_class {
  background: #c4dfc9;
}

.fresult_schemedata .fresult_route.selected .fresults_routeinfo i {
  color: #5a8a63;
}

.fresult_route.selected .fresults_schemeicons .butaca .fresults_seats_text,
.fresult_route.selected .fresults_schemeicons .fresults_baggagetxt,
.fresult_route.selected .fresults_schemeicons .fresults_class {
  color: var(--ct-color_main);
}

#fresults_list .fresult_schemeprice {
  color: var(--ct-color_main);
  background: #dbeaf5;
}

.fresult_schemeprice .fresults_rate {
  font-size: 0.85em;
}

.fresult_schemeprice .fresults_rate strong {
  font-weight: 600;
}

.fresult_schemeprice .fresults_price {
  margin-top: 0;
  font-weight: 300;
}

.fresult_schemeprice .fresults_routeinfo {
  margin-bottom: 4px;
}

.fresult_schemeprice .fresults_routeinfo .info3 {
  display: inline-block;
  width: 28px;
  height: 28px;
  padding: 5px 0 0 1px;
  font-size: 1em;
}

.fresult_schemeprice .fresults_routeinfo .personas {
  display: inline-block;
  width: 40px;
  height: 28px;
  padding: 5px 0 0 1px;
  font-size: 1em;
}

.fresult_schemeprice .fresults_routeinfo .cash {
  display: inline-block;
  width: 28px;
  height: 28px;
  padding: 5px 0 0 1px;
  font-size: 1em;
}

.fresult_schemeprice .fresults_routeinfo.enabled .info3 {
  opacity: 1;
  color: var(--ct-color_aux);
  border-radius: 14px;
  background: #fff;
  transition: all 0.08s;
}

.fresult_schemeprice .fresults_routeinfo.enabled .info3:hover {
  color: #fff;
  background: var(--ct-color_aux);
}

.fresult_schemeprice .fresults_routeinfo.enabled .personas {
  opacity: 1;
  color: var(--ct-color_aux);
  border-radius: 14px;
  background: #fff;
  transition: all 0.08s;
}

.fresult_schemeprice .fresults_routeinfo.enabled .personas:hover {
  color: #fff;
  background: var(--ct-color_aux);
}

.fresult_schemeprice .fresults_routeinfo.enabled .cash {
  opacity: 1;
  color: var(--ct-color_aux);
  border-radius: 14px;
  background: #fff;
  transition: all 0.08s;
}

.fresult_schemeprice .fresults_routeinfo.enabled .cash:hover {
  color: #fff;
  background: var(--ct-color_aux);
}

.fresult_schemeprice .buttonyellow {
  width: 100%;
  padding: 12px 18px;
  font: 600 1.24rem var(--ct-font-main_family) !important;
  letter-spacing: 0.01em;
  border-radius: 23px;
}

/*Colors*/
.fresult_schemeprice .buttonyellow {
  box-shadow: 0 1px 5px 3px rgba(173, 204, 224, 1);
}

.fresult_schemeprice [disabled].buttonyellow {
  color: #6e8799;
  box-shadow: none;
  background: #c4d5e2;
}

/*Paginas*/
.stopper-modal {
  background: rgba(219, 234, 245, 0.95) !important;
}

.basic__table {
  box-shadow: 0 0 3px 0 rgba(128, 138, 153, 0.3);
  border-spacing: 0 !important;
  border-bottom: 7px solid #fff !important;
}

.basic__table .basic__table__head tr,
.basic__table .basic__table__body tr {
  height: 56px;
}

.basic__table .basic__table__head {
  background: #eaf1f5;
}

.basic__table__head__container {
  text-transform: none;
}

.basic__table .basic__table__head .-icon-hide,
.basic__table .basic__table__head .-icon-hide:hover {
  text-transform: none;
  color: var(--ct-color_main);
}

.basic__table .basic__table__body tr {
  font-size: 0.8em;
  color: var(--ct-color_main) !important;
  border-left: 0;
  background: #f3f6f8;
}

.basic__table__body tr:nth-child(2n + 1) {
  background: #fff;
}

.is_tr .document::before {
  width: 6px !important;
  border-radius: 2px !important;
  box-shadow: 0 0 3px 0 rgba(128, 138, 153, 0.3);
}

.cms__openHTML__content__yellow__block,
.cms__openHTML__content__plane-ball__inside {
  font-size: 1.6rem !important;
  color: #fff;
}

.mta-contact .cms__openHTML__content__yellow__block a {
  font-size: inherit !important;
}

.mybookings__header-container.max-width-page,
.mymessages__header-container.max-width-page,
sbmn-my-travel-agency .brand_header {
  margin-bottom: 20px;
  margin-top: 20px;
  padding-left: 80px;
  text-align: left;
}

.brand_header.Calendario {
  margin-bottom: 20px;
  margin-top: 20px;
  background-color: transparent;
}

.mybookings__header-container .mybookings__header__icon,
.mymessages__header-container .mymessages__header__icon {
  top: 4px;
}

.mymessages__container__legend .code__unread {
  padding: 8px 6px !important;
  color: #4c4c4c;
  border: 0;
  box-shadow: 0 0 3px 0 rgba(128, 138, 153, 0.3);
  background: #fff;
}

.mymessages__container__legend .code__unread.okselected {
  color: var(--ct-color_aux_alt);
  background-color: var(--ct-color_aux);
}

.mymessages__container__legend .code__unread:before {
  bottom: 12px !important;
}

.mymessages__container__legend .code__unread:hover {
  color: var(--ct-color_link_hover_alt);
  background-color: var(--ct-color_main);
}

.mybookings__legend>span {
  opacity: 1 !important;
  min-width: 0 !important;
  margin: 3px !important;
  padding: 8px 6px !important;
  font-size: 1.25em !important;
  border-bottom: 0 !important;
  border-left-style: solid;
  border-left-width: 3px;
  border-radius: 3px;
  box-shadow: 0 0 3px 0 rgba(128, 138, 153, 0.3);
  background: #fff;
}

.mybookings__legend>span:hover {
  border-color: var(--ct-color_main) !important;
  color: #fff !important;
  background: var(--ct-color_main) !important;
}

.mybookings__legend>span.okselected {
  padding-right: 22px !important;
  color: #fff;
  border-color: var(--ct-color_aux) !important;
  background: var(--ct-color_aux) !important;
}

.mybookings__legend span:hover::before {
  display: none !important;
}

.mybookings__legend span::before {
  top: 11px !important;
  right: 5px !important;
  color: #fff !important;
}

.mybookings__legend strong {
  position: relative;
  top: -4px;
  width: 0 !important;
  border-right: #7a9ab2 3px dotted;
  margin: 0 10px;
  display: inline-block;
  opacity: 1 !important;
  border-radius: 0 !important;
  background: none !important;
}

.mybookings__container__nobookings.Berror,
.mymessages__container__nomsgs.Berror {
  display: block;
  width: 96%;
  height: auto;
  margin: 10px auto;
  font-size: 1.4em;
  border-radius: 2px;
  border: 1px #e5d1d2 solid;
  background: #f3dedf !important;
}

sbmn-flights-passengers {
  background: #f3f6f8 !important;
}

.fsummary__top__ida-vuelta__flights__published {
  color: #fff !important;
  background-color: var(--ct-color_aux);
}

.fsummary__top__ida-vuelta__flights__dates {
  color: var(--ct-color_main);
}

.fsummary__content__left__container__block__left__date,
.fsummary__content__right__container__item strong,
.fsummary__content__right__container__pasajero {
  color: var(--ct-color_aux);
}

.cms__openHTML__header__title {
  font-size: 1.4em;
  text-align: left !important;
  text-transform: none !important;
}

/* Loader */
.cssload-loader>div:nth-child(1) {
  color: var(--ct-color_aux) !important;
}

.cssload-loader>div:nth-child(2) {
  color: var(--ct-color_main) !important;
}

sbmn-access {
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 0;
  background-color: var(--ct-color_main);

  &::before {
    display: none;
  }

  &>header {
    width: 50% !important;
    max-width: auto;
    right: 0 !important;
    background-image: none;
    background-color: var(--ct-color_main);
  }

  header span {
    color: var(--ct-color_aux_alt);
  }

  figure {
    height: auto;
    left: auto;
    position: default;
    background: url('/assets/img/logos/main_logo.png') no-repeat 50% 50%;
    background-size: 250px 93px;
  }

  figure img {
    visibility: hidden;
  }

  #login-new-account {
    padding: 2em;
    left: 0;
  }

  .login-reset-nav,
  #login-new-account a {
    text-decoration: none;
    text-transform: none;
    color: var(--ct-color_aux);
  }
}

sbmn-register {
  min-width: auto;
  max-width: 600px;

  .returnlink {
    top: 10px;
    left: 10px;
    text-decoration: none;
    text-transform: lowercase;
    color: var(--ct-color_aux);
  }

  .returnlink:hover {
    color: var(--ct-color_link_hover);
  }
}

#login-container,
sbmn-register>section {
  padding: 3.5em 0.5em;
  border-radius: 15px;
  -webkit-box-shadow: 0 0 24px 8px rgba(21, 229, 129, 0.5);
  box-shadow: 0 0 24px 8px rgba(21, 229, 129, 0.5);
  background: #fff;
}

sbmn-register>section {
  padding: 3.5em 3.5em 10em;
}

#login-access h1,
#login-change-password h1,
#login-submit-password h1 {
  margin-bottom: 20px !important;
}

#login-access h1 strong,
#login-change-password h1 strong,
#login-submit-password h1 strong {
  display: block !important;
}

.bmanager__info__container__route__flight__extrainfo__fare {
  color: #fff !important;
}

.passengers__section__passenger__title {
  background-color: var(--ct-color_main);
}

.passengers__section__passenger__data__title,
.passengers__section__passenger__data__title__note {
  color: rgba(0, 153, 255, 0.8) !important;
}

#fresults_ads figure img {
  width: 100%;
  height: auto;
}

/* calendar */
.mat-calendar-body-selected {
  color: var(--ct-color_aux_alt);
  background: var(--ct-color_aux);
}

.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: var(--ct-color_link_hover_alt);
  background: var(--ct-color_link_hover);
}

@media (min-width: 1022px) {
  #main_header .main_header__logo {
    position: absolute;
    margin-left: -90px;
    left: 50%;
  }

  #searchEngineForm {
    border-radius: 3px !important;
    border-left: 2px var(--ct-color_aux) solid;
  }

  #searchEngineForm #destdates_search-engine {
    min-width: 60%;
    max-width: 60%;
    width: 60%;
    flex-direction: column;
  }

  #searchEngineForm #destdates_search-engine .destdate_container {
    flex-direction: row;
  }

  #searchEngineForm #destdates_search-engine .idavuelta {
    position: absolute;
    left: 50%;
    top: 40px;
    right: auto;
    margin-left: -11px;
    transform: none;
    border: 0;
  }

  .Multidestino_search #searchEngineForm #destdates_search-engine .idavuelta {
    margin: 0;
    top: 35px;
    left: 38%;
  }

  #searchEngineForm #passengers_search-engine {
    min-width: 10%;
    max-width: 10%;
    width: 10%;
  }

  #searchEngineForm #options_search-engine {
    min-width: 28%;
    max-width: 28%;
    width: 28%;
    padding: 25px;
    position: absolute;
    top: 75px;
    bottom: 0;
    right: 0;
    border-radius: 0 60px 0 0;
  }

  #searchEngineForm #options_search-engine fieldset {
    min-width: 100% !important;
    max-width: 100% !important;
    width: 100% !important;
  }

  .Simple_search #searchEngineForm #options_search-engine fieldset:nth-of-type(1),
  .Calendario_search #searchEngineForm #options_search-engine fieldset:nth-of-type(1) {
    top: 36%;
    left: -180% !important;
    width: 100px !important;
    min-width: 100px !important;
    max-width: 100px !important;
    padding: 17px 0;
  }

  .Simple_search #searchEngineForm #options_search-engine fieldset:nth-of-type(1) label,
  .Calendario_search #searchEngineForm #options_search-engine fieldset:nth-of-type(1) label {
    color: var(--ct-color_main) !important;
  }

  .Simple_search #searchEngineForm #options_search-engine fieldset:nth-of-type(1) label:hover,
  .Calendario_search #searchEngineForm #options_search-engine fieldset:nth-of-type(1) label:hover {
    color: var(--ct-color_aux) !important;
  }

  .Simple_search #searchEngineForm #options_search-engine fieldset:nth-of-type(1) label:hover .mat-checkbox-frame,
  .Calendario_search #searchEngineForm #options_search-engine fieldset:nth-of-type(1) label:hover .mat-checkbox-frame {
    border-color: var(--ct-color_aux) !important;
  }

  .Simple_search #searchEngineForm #options_search-engine fieldset:nth-of-type(1) .mat-checkbox-frame,
  .Calendario_search #searchEngineForm #options_search-engine fieldset:nth-of-type(1) .mat-checkbox-frame {
    border-color: var(--ct-color_main) !important;
  }

  #searchEngineForm #moreopts_search-engine {
    width: 70%;
    flex-direction: row;
  }

  #searchEngineForm #submit_search-engine {
    top: 75px;
    height: calc(100% - 75px);
  }

  sbmn-flights-messages {
    margin-top: 100px !important;
  }
}

#simpledates_search-engine .simpledates_dateview:last-child {
  left: 0 !important;
}

@media (max-width: 1022px) {
  sbmn-access>header {
    width: 100% !important;
  }

  sbmn-access>section {
    width: 100% !important;
  }

  sbmn-flights-dashboard .max-width-page> :nth-child(3),
  .mybookings__header-container.max-width-page,
  .brand_header {
    margin-top: 60px;
  }

  .mybookings__header__icon::before {
    font-size: 2rem !important;
  }

  .fresult_schemedata .fresult_route.selected::before {
    right: 5px !important;
  }

  #gradient_search-engine.Simpleborder span,
  #gradient_search-engine.Calendarioborder span,
  #gradient_search-engine.Multidestinoborder span {
    display: none;
  }
}

.leftdialog {
  padding-bottom: 20px;

  .extendedinfo__container__div {
    position: absolute;
    top: 180px;
    bottom: 25px;
    display: block;

    .extendedinfo__container__code {
      max-height: 100%;
      max-width: 760px;
    }
  }
}

.Berror .stopper-modal__scroll__container__content {
  background-color: rgba(0, 0, 0, 0.8);
  padding: 15px;
}

.calendargrid {
  padding-right: 55px;
}

.voucher__header__container__state-loc__state,
.voucher__container__flights__container__content__info,
.voucher__container__flights__container__content__flight__stops__container__logo::before {
  background-color: var(--ct-color_main) !important;
  color: var(--ct-color_main_alt) !important;
}

#gradient_search-engine {

  &.Simpleborder,
  &.Calendarioborder,
  &.Multidestinoborder {
    span {
      top: 90px;
      left: 40px;
      right: auto;
      z-index: 1;
      color: var(--ct-color_main);
      height: 40px;
      font-size: 1.6rem;
    }
  }
}

#searchEngineForm {
  .Simple_search & {
    padding-top: 40px;
  }

  .Multidestino_search & {
    padding-top: 40px;
  }

  .Calendario_search & {
    padding-top: 40px;
  }
}

.summary #searchEngineForm {
  padding: 0;
}

.agreement_form .agreement_sms {
  display: none;
}

sbmn-flights-dashboard sbmn-flights-offers {
  & h2 {
    display: none;
  }

  & #list_offers {
    display: flex;
  }

  & .banner_home_footer {
    display: flex;
    justify-content: space-between;
    margin: 60px 0 20px 0;
    flex-wrap: wrap;

    & div {
      width: 50%;
      padding: 10px;
      height: 464px;
      overflow: hidden;
    }
  }
}

i.m_icon:before {
  color: var(--ct-color_aux);
}

.exportxls:hover i.m_icon:before {
  color: var(--ct-color_link_hover);
}

.bmanager__nav__section__item.baggage {
  display: none;
}

.mtamain__logo__uploader {
  color: var(--ct-color_aux) !important;
}

.mtamain__container__section:before,
.passengers__section:before,
.mtamain__container__section:before {
  color: #9cb5d3;
}

fieldset:has(mat-checkbox[formcontrolname='SinTourOperacion']) {
  display: none !important;
}

#options_search-engine {
  display: flex;
  flex-direction: column;
  justify-content: start;
  gap: 30px;
}

sbmn-flights-results {
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-columns: auto 1fr;
  grid-template-areas:
    'header header'
    'sidebar main';
}

sbmn-flights-results>header {
  grid-area: header;
}

sbmn-flights-results>section.fresults_filters {
  grid-area: sidebar;
  position: relative;
  width: 280px;
  min-width: auto;
  box-shadow: none;
  background-color: transparent !important;
}

sbmn-flights-filters section {
  min-width: auto;
}

sbmn-flights-results>section#fresults {
  grid-area: main;
}

sbmn-flights-results>#fcalendar {
  grid-area: main;
}

sbmn-flights-filters section .clock+span {
  display: block;
  margin-top: 2px;
  margin-left: 22px;
  text-align: left;
}

.mybookings__container table tr *:nth-child(6n) {
  display: none;
}

#main_header .mobile_menu_container__sidenav {
  max-width: 220px;
}

.bmanager__split__container__booking__psg .menosopciones {
  color: #000;
  opacity: 0.4;
}

.h4withbold strong,
.h4withbold span {
  font-size: 20px;
  color: #000;
}

sbmn-my-bookings-import h2 {
  margin-bottom: 3rem !important;
}

sbmn-my-bookings-import div.stopper-modal__scroll__container>div {
  background-color: $Bwhite;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 1.25rem;
  text-align: left;
  position: relative;
  overflow: hidden;
  border-radius: 25px;
}

sbmn-my-bookings-import div.stopper-modal__scroll__container>div>div:nth-child(1) {
  flex: 1 1 0;
  margin-right: 0.75rem;
  border: 0px solid transparent;
  border-radius: 0.75rem;
  overflow: hidden;
  color: $Bgrey8;

  p:first-child {
    font-size: large;
    background-color: var(--ct-color_aux) !important;
    margin: 0;
    padding: 1rem 1.25rem;
    border-bottom: 1px solid $Bblue;
  }

  div:nth-child(2) {
    font-size: medium;
    background-color: $Bgrey05;
    margin: 0;
    padding: 1rem 1.25rem;
    line-height: normal;
  }

  div:nth-child(2)>* {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }
}

sbmn-my-bookings-import div.stopper-modal__scroll__container>div>div:nth-child(2) {
  flex: 1 1 0;
  margin-left: 0.75rem
}

sbmn-my-bookings-import div.stopper-modal__scroll__container>div>div:nth-child(2)>div {
  display: flex;
  flex-direction: column;
  height: 100%;

  form {
    flex: 1 1 auto;
    align-content: center;
  }

  button {
    @extend %button;
    align-self: flex-end;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    background-color: var(--ct-color_aux) !important;
    color: $Bwhite;
  }
}

sbmn-my-bookings>header.mybookings__header {
  div.mybookings__header-container {
    display: flex;
    justify-content: space-between;
    padding: 0px 1.5rem;
  }

  h1 {
    text-align: center;
  }

  div.mybookings__header-container>* {
    flex: 1 1 0;
  }

  div:last-child {
    align-content: center;

    button {
      @extend %button;
      background-color: var(--ct-color_aux) !important;
      border: 2px solid var(--ct-color_aux) !important;
      float: right;
      color: $Bwhite;
    }
  }
}